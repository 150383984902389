import React, { useState, useEffect } from 'react';
import {
  ChakraProvider, Table, Thead, Tbody, Tr, Th, Td, Box, IconButton, TableContainer, Button, Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,} from '@chakra-ui/react';
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { CheckIcon } from '@chakra-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { get, post } from '../../api';

const muiTheme = createTheme();

const PopoverMenu = ({reportId}) => {
  const navigate = useNavigate();

  const viewReport = async (reportId) =>{
    console.log("View report")
    // navigate('/reports');
    navigate(`/reports/${reportId}`);
  }

  const downloadReport = async(reportId) => {
    try {
        const response = await post(`report/downloadReport/${reportId}`, {}, { responseType: 'blob' });

        // Create a link element and trigger the download
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report_${reportId}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        console.error('Error downloading report:', error);
    }

}


  // const downloadReport = async (reportId) => {
  //   try {
  //       const response = await get(`/assessment/${reportId}/report`, {
  //           headers: {
  //               'Content-Type': 'application/json'
  //           }
  //       });

  //       const { filename, fileData, contentType } = response.data;
  //       // Decode base64 to binary
  //       const byteCharacters = atob(fileData);
  //       const byteNumbers = new Array(byteCharacters.length);
  //       for (let i = 0; i < byteCharacters.length; i++) {
  //           byteNumbers[i] = byteCharacters.charCodeAt(i);
  //       }
  //       const byteArray = new Uint8Array(byteNumbers);

  //       const blob = new Blob([byteArray], { type: contentType });
  //       const url = window.URL.createObjectURL(blob);
        
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', filename);
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //       console.error('Error downloading the file:', error);
  //   }
  // };

    return (<>
      <Menu size="sm">
        <MenuButton as={Button}
          bg="white"
          color="black"
          height="1.2rem"
          _hover={{ bg: "white" }}
          _active={{ bg: "white" }}
          _focus={{ bg: "white" }}
        >
          <MoreVertRoundedIcon />
        </MenuButton>
        <MenuList width="fit-content" minWidth="0" p={4} borderColor='#EFF4FF' borderRadius='12px'>
          <MenuItem borderRadius='5px' bg="white" _hover={{ bg: "#EFF4FF" }} color='#00359E' onClick={() => viewReport(reportId)}>View Report</MenuItem>
          <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
          <MenuItem borderRadius='5px' bg="white" _hover={{ bg: "#EFF4FF" }} color='#00359E' onClick={() => downloadReport(reportId)}> Download Report </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

function AdminAssessmentTableComponent({ searchTerm, sortOption = { sortBy: 'assessmentName', sortOrder: 'asc', status: '' }, selectedItems, currentPage, setCurrentPage, updateNavigateState, isAdmin, userId }) {
    const navigate = useNavigate();
    const location = useLocation();
    const pageSize = 10;
    const [assessmentData, setAssessmentData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedOrder, setSelectedOrder] = useState(sortOption.sortOrder || 'asc');
    const [selectedSortBy, setSelectedSortBy] = useState(sortOption.sortBy || 'userName');

    const fetchUserDetails = async (page, searchTerm, sortBy, order, status = []) => {
      try {
        const response = await get(`assessment/${userId}/list`, {
          params: {
            page: page,
            limit: pageSize,
            query: searchTerm,
            sortBy: sortBy,
            sortOrder: order,
            sortStatus: status,
            isAdmin: isAdmin,
          }
        });
        setAssessmentData(response.data.data || []);
        setTotalPages(response.data.totalPages || 0);
      } catch (error) {
        console.error('Error fetching assessment list:', error);
      }
    };
  
    const handleChangePage = (event, newPage) => {
      setCurrentPage(newPage);
      fetchUserDetails(newPage, searchTerm, selectedSortBy, selectedOrder, sortOption.status);
      updateNavigateState(newPage, searchTerm, sortOption, selectedItems);
    };

    useEffect(() => {
      fetchUserDetails(currentPage, searchTerm, selectedSortBy, selectedOrder, sortOption.status, sortOption.role);
    }, [isAdmin,userId,selectedSortBy, selectedOrder, searchTerm, sortOption, currentPage]);

    useEffect(() => {
      const interval = setInterval(() => {
        if (assessmentData.some(assessment => assessment.status === 'In Process')) {
          fetchUserDetails(currentPage, searchTerm, selectedSortBy, selectedOrder, sortOption.status);
        }
      }, 300000);
  
      return () => clearInterval(interval);
    }, [assessmentData, isAdmin,userId, currentPage, searchTerm, selectedSortBy, selectedOrder, sortOption])
  
    const handleSortChange = (sortBy, order) => {
      setSelectedOrder(order);
      setSelectedSortBy(sortBy);
      setCurrentPage(1);
      updateNavigateState(1, searchTerm, { ...sortOption, sortBy, sortOrder: order }, selectedItems);
      fetchUserDetails(1, searchTerm, sortBy, order, sortOption.status, sortOption.role);
    };

    const statusColors = {
      "Completed": "#12B76A",
      "In Process": "#FF692E",
    };

  return (
    <ChakraProvider>
      <Box width="100%" py={4}>
        <TableContainer width="full" border="1px solid" borderColor="#D1E0FF" borderRadius="xl">
          <Table variant="simple">
          <Thead >
              <Tr>
                <Th py={4} borderColor="#D1E0FF" colSpan={6} color='black' fontSize={'18px'} backgroundColor={'#F5F8FF'} textTransform="none">All Assessment</Th>
                <Th py={3} borderColor="#D1E0FF"  backgroundColor={'#F5F8FF'} display={'flex'} justifyContent={'flex-end'}>
                <Menu>
                    <MenuButton as={Button} rightIcon={<FilterListOutlinedIcon />} 
                      bg="#D1E0FF"
                      color="#00395E"
                      _hover={{ bg: "#c5d7fc" }}
                      _active={{ bg: "#c5d7fc" }}
                      _focus={{ bg: "#c5d7fc" }}
                      textAlign="right"
                      h="38px"
                    >
                      Sort
                    </MenuButton>
                    <MenuList width="fit-content" minWidth="0" p={4} borderColor="#EFF4FF" borderRadius="12px">
                      <MenuItem borderRadius="5px" bg={selectedOrder === 'asc' ? "#EFF4FF" :"white"} _hover={{ bg: "#EFF4FF" }} color="#00359E" onClick={() => handleSortChange('assessmentName', 'asc')}>
                        Alphabetical Asc. (A to Z)
                        {selectedOrder === 'asc' && (
                          <Box as="span" color="#00359E" ml={4}>
                            <CheckIcon boxSize={3} />
                          </Box>
                        )}
                      </MenuItem>
                      <MenuDivider borderWidth="1px" borderColor="#D1E0FF" />
                      <MenuItem borderRadius="5px" bg={selectedOrder === 'desc' ? "#EFF4FF" :"white"} _hover={{ bg: "#EFF4FF" }} color="#00359E" onClick={() => handleSortChange('assessmentName', 'desc')}>
                        Alphabetical Des. (Z to A)
                        {selectedOrder === 'desc' && (
                          <Box as="span" color="#00359E" ml={4}>
                            <CheckIcon boxSize={3} />
                          </Box>
                        )}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Th>
              </Tr>
            </Thead>
            <Thead >
              <Tr>
                <Th py={4} fontSize={'14px'} borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} textTransform="none">Assessment Name</Th>
                <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} textTransform="none">User Name</Th>
                <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} textTransform="none">Comapny Name</Th>
                <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} textTransform="none">ESG Score</Th>
                <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} textTransform="none">Date</Th>
                <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} textTransform="none">Status</Th>
                <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} textTransform="none">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {assessmentData.map(item => (
                <Tr key={item.assessmentId}>
                  <Td borderRight="1px solid" borderColor="#D1E0FF" color='#6C737F' fontSize='14px' fontWeight='500'>{item.assessmentName}</Td>
                  <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='#6C737F' fontSize='14px' fontWeight='500'>{item.userName}</Td>
                  <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='#6C737F' fontSize='14px' fontWeight='500'>{item.companyName}</Td>
                  <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='#6C737F' fontSize='14px' fontWeight='500'>{item.esgScore === null ? '-' : item.esgScore}</Td>
                  <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='#6C737F' fontSize='14px' fontWeight='500'>{new Date(item.createdAt).toLocaleDateString('en-GB')}</Td>
                  <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color={statusColors[item.status] || "#2970FF"}fontSize='14px' fontWeight='500'>{item.status}</Td>
                  <Td verticalAlign="middle" textAlign="center" borderColor="#D1E0FF">{item.status !== 'Completed' ? '-' :<PopoverMenu reportId={item.reportId}/>}</Td>
                </Tr>
              ))}
              <Tr>
                <Th colSpan={7} verticalAlign="middle" textAlign={'center'} backgroundColor={'#F5F8FF'}>
                    <ThemeProvider theme={muiTheme}>
                    <Box  w='100%' display="flex" justifyContent="center" alignItems="center" >
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handleChangePage}
                        variant="outlined" 
                        shape="rounded"
                        sx={{
                            '& .MuiPaginationItem-root': {
                              borderColor: '#D1E0FF',
                              backgroundColor: 'white',
                              color: 'black',
                              '&:hover': {
                                backgroundColor: 'white',
                                color:'#2970FF',
                                borderColor: '#2970FF',
                              },
                              '&.Mui-selected': {
                                backgroundColor: 'white',
                                color:'#2970FF',
                                borderColor: '#2970FF',
                              }
                            },
                            '& .MuiPaginationItem-previousNext': {
                                backgroundColor: '#F5F8FF',
                                borderColor: '#D1E0FF',
                                color: '#9E9E9E',
                                '&:hover': {
                                  backgroundColor: '#F5F8FF',
                                  borderColor: '#2970FF',
                                  color: '#2970FF',
                                },
                                '&.Mui-selected': {
                                    backgroundColor: '#F5F8FF',
                                    color:'#2970FF',
                                    borderColor: '#2970FF',
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: '#6C737F',
                                    color:'#EEEEEE',
                                    borderColor: '#6C737F',
                                }
                            }
                          }}
                    />
                    </Box>
                    </ThemeProvider>
                </Th>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </ChakraProvider>
  );
}

export default AdminAssessmentTableComponent;
