import '../../App.css';
import React from 'react';
import {ChakraProvider,Box, Button, useToast, Spinner, Text, Grid, GridItem, Icon, Input, Wrap, WrapItem, VStack, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Heading, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import {ChevronDownIcon, ChevronUpIcon} from '@chakra-ui/icons';
import { ReactComponent as fileIcon } from '../../icons/fileIcon.svg';
import { ReactComponent as linkIcon } from '../../icons/linkIcon.svg';
import { CheckIcon } from '@chakra-ui/icons';
import { patch, get } from '../../api';
import { useLocation, useNavigate } from 'react-router-dom';

function Generatereport() {
    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    const { assessmentId } = location.state || {};
    const [selectedOption, setSelectedOption] = React.useState('');
    const [companyName, setCompanyName] = React.useState('');
    const [numberOfEmployees, setNumberOfEmployees] = React.useState('');
    const [uploadeddocumnets, setUploadeddocumnets] = React.useState([]);
    const [uploadedlinks, setUploadedlinks] = React.useState([]);
    const [errors, setErrors] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [menuButtonWidth, setMenuButtonWidth] = React.useState(null);
    const menuButtonRef = React.useRef();
  
    React.useEffect(() => {
      if (menuButtonRef.current) {
        setMenuButtonWidth(menuButtonRef.current.clientWidth);
      }
    }, [menuButtonRef.current]);

    const validate = (field, value) => {
        let error = '';

        switch (field) {
            case 'companyName':
                const companyNameRegex = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+){0,49}$/;
                if (!value) {
                    error = 'Company name is required';
                } else if (!companyNameRegex.test(value)) {
                    error = 'Company name is invalid';
                }
                break;
            case 'numberOfEmployees':
                if (!value) {
                    error = 'Number of employees is required';
                } else if (isNaN(value)) {
                    error = 'Number of employees must be a number';
                } else if (value <= 0) {
                    error = 'Number of employees must be a greater than 0';
                }
                break;
            case 'industryType':
                if (value === '') {
                    error = 'Industry type is required';
                }
                break;
            default:
                break;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: error,
        }));

        return error;
    };

    const handleBlur = (field) => (e) => {
        validate(field, e.target.value);
    };

    React.useEffect(() => {
        const fetchfilesDetails = async () => {
            try {
                const response = await get(`assessment/${assessmentId}/assessment`);
                setCompanyName(response.data.data.assessment.companyName)
                setNumberOfEmployees(response.data.data.assessment.companySize)
                setSelectedOption(response.data.data.assessment.industryType)
                setUploadeddocumnets(response.data.data.uploadedDocuments);
                setUploadedlinks(response.data.data.uploadedLinks);
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };

        fetchfilesDetails();
    }, [assessmentId]);

    const options = [
        "Oil & Gas Exploration & Production",
        "Life & Health Insurance Carriers",
        "Pension Funds",
        "Commercial Real Estate",
        "Car & Automobile Sales",
        "Direct General Insurance Carriers",
        "Commercial Banks",
        "Car & Automobile Manufacturing",
        "Coal Mining",
    ];

    const handleSubmit = async () => {
        const fields = [
            'companyName',
            'numberOfEmployees',
            'industryType',
        ];

        let hasErrors = false;

        fields.forEach((field) => {
            const value = eval(field === 'industryType' ? 'selectedOption' : field);
            const error = validate(field, value);
            if (error) {
                hasErrors = true;
            }
        });

        if (hasErrors) {
            return;
        }

        const data = {
            companyName: companyName,
            companySize: parseInt(numberOfEmployees),
            industryType: selectedOption,
        };

        setIsLoading(true);
        try {
            const response = await patch(`assessment/${assessmentId}/assessment`, data, { timeout: 600000 });

            // Show success toast
            toast({
                title: response.data.message,
                description: 'Assessment has been created successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });

            setNumberOfEmployees('');
            setSelectedOption('');
            setCompanyName('');
            setTimeout(() => {
                navigate('/assessment');
            }, 1000); 
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleNavigation = (path) => {
        navigate(path, { state: { assessmentId:assessmentId } });
    };
    
  return (
    <>
      <ChakraProvider>
      <Box display='flex' flexDirection='column' height='100%'>
        <Box flex='1' overflowY='auto' display='flex' flexDirection='column' justifyContent='space-between' w="100%" h='fit-content'>
           <Box py={{ base: 7, md: 10 }} px={{ base: 5, md: 10 }}>
            <Breadcrumb separator='>' color="#818589" fontWeight='400' fontSize='sm' mb={2}>
                <BreadcrumbItem>
                    <BreadcrumbLink href='/assessment'>Create Assessment</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink as="button" onClick={() => handleNavigation('/upload')}>Upload Files</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink as="button" onClick={() => handleNavigation('/generateReport')}>Generate Report</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <Box>
                <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={{base:0, md:6}}>
                    <GridItem w='100%' h='fit-content'>
                        <Box mb="5">
                            <Text fontWeight="600" fontSize='20px' color='#2E3646' mb="2">
                                Name of company
                            </Text>
                            <Input 
                                variant='outline'
                                border='1px solid #F3F4F6'
                                focusBorderColor={errors.companyName ? '#F04438' : '#2970FF'}
                                bg="white"
                                placeholder='Enter Company name'
                                size='md'
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                onBlur={() => handleBlur('companyName')}
                                borderColor={errors.companyName ? '#F04438' : '#F3F4F6'}
                                sx={{
                                    '::placeholder': {
                                        color: '#9DA4AE',
                                    },
                                    whiteSpace: 'nowrap',
                                    overflow: 'auto',
                                }}
                            />
                            {errors.companyName && <Text color="#F04438" fontSize="sm">{errors.companyName}</Text>}
                        </Box>
                    </GridItem>
                    <GridItem w='100%' h='fit-content'>
                    <Box mb={5}>
                        <Text fontWeight="600" fontSize='20px' color='#2E3646' mb="2">
                            Select the industry type
                        </Text>
                        <Menu >
                        {({ isOpen }) => (<>
                            <MenuButton 
                              ref={menuButtonRef}
                              w='100%' bg='white'                              
                              border='1px solid'
                              borderColor={ errors.industryType ? '#F04438' : '#F3F4F6' }
                              fontWeight='400'
                              _hover={{ bg: 'white', borderColor: errors.industryType ? '#F04438' : '#2970FF' }}
                              _active={{ bg: 'white', borderColor: errors.industryType ? '#F04438' : '#2970FF' }}
                              _focus={{ bg: 'white', borderColor: errors.industryType ? '#F04438' : '#2970FF' }}
                              as={Button} textAlign='left' 
                              rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                              color={!selectedOption ? "#9DA4AE" : "inherit"}
                            >
                                {selectedOption || 'Select the option'}
                            </MenuButton>
                            <MenuList width={menuButtonWidth ? `${menuButtonWidth}px` : 'auto'} p={2} borderColor='#EFF4FF' borderRadius='12px' >
                                {options.map((option, index) => (
                                    <React.Fragment key={index}>
                                    <MenuItem
                                        w='100%'
                                        onClick={() => setSelectedOption(option)}
                                        borderRadius="5px"
                                        bg={selectedOption === option ? "#EFF4FF" : "white"}
                                        _hover={{ bg: "#EFF4FF" }}
                                        color="#00359E"
                                        textAlign="left"
                                    >
                                        <Box w="100%" display="flex" justifyContent="space-between" alignItems="center">
                                            {option  || 'Select the option'}
                                            {selectedOption === option && (
                                            <Box as="span" color="#00359E" ml={2}>
                                                <CheckIcon boxSize={3} />
                                            </Box>
                                            )}
                                        </Box>
                                    </MenuItem>
                                    {index < options.length - 1 && <MenuDivider borderWidth="1px" borderColor="#D1E0FF" />}
                                    </React.Fragment>
                                ))}
                            </MenuList>
                            </>)}
                        </Menu>
                        {errors.industryType && <Text color="#F04438" fontSize="sm">{errors.industryType}</Text>} 
                    </Box>
                    </GridItem>
                </Grid>
                <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
                    <GridItem w='100%' h='fit-content'>
                        <Box mb="5">
                            <Text fontWeight="600" fontSize='20px' color='#2E3646' mb="2">
                                Number of Employees in company
                            </Text>
                            <Input 
                                variant='outline'
                                border='1px solid #F3F4F6'
                                focusBorderColor={errors.numberOfEmployees ? '#F04438' : '#2970FF'}
                                bg="white"
                                type='number'
                                placeholder='Enter size of the company'
                                size='md'
                                value={numberOfEmployees}
                                onChange={(e) => setNumberOfEmployees(e.target.value)}
                                onBlur={() => handleBlur('numberOfEmployees')}
                                borderColor={errors.numberOfEmployees ? '#F04438' : '#F3F4F6'}
                                sx={{
                                    '::placeholder': {
                                        color: '#9DA4AE',
                                    },
                                    whiteSpace: 'nowrap',
                                    overflow: 'auto',
                                }}
                            />
                            {errors.numberOfEmployees && <Text color="#F04438" fontSize="sm">{errors.numberOfEmployees}</Text>}
                        </Box>
                    </GridItem>
                </Grid>
            </Box>
            <br/>
            <Box w='100%' bg='#ECFDF3' p='20px' h='fit-content' borderRadius='12px' border= '2px solid #ECFDF3'>
                <Heading fontWeight='600' fontSize='20px' mb={1} color="black">List of Uploaded Documents</Heading>
                <br/>
                <Wrap  spacingX={20} spacingY={6} align="center" justify="start">
                    {uploadeddocumnets && uploadeddocumnets.map((name, index) => (
                        <WrapItem key={index}>
                            <VStack >
                                <Box display='flex' justifyContent='center' alignItems='center'>
                                    <Icon as={fileIcon} w="13px" h="17px" />
                                    <Text px={2} fontSize="14px" fontWeight="500" >{name.name}</Text>
                                </Box>
                            </VStack>
                        </WrapItem>
                    ))} 
                </Wrap >
            </Box>
            <br/>
            <Box w='100%' bg='#EFF4FF' p='20px' h='fit-content' borderRadius='12px' border= '2px solid #EFF4FF'>
                <Heading fontWeight='600' fontSize='20px' mb={1} color="black">List of Links Uploaded</Heading>
                <br/>
                {uploadedlinks && uploadedlinks.length > 0 ? (
                    <Wrap spacingX={20} spacingY={6} align="center" justify="start">
                        {uploadedlinks && uploadedlinks.map((link, index) => (
                            <WrapItem key={index}>
                                <VStack>
                                    <Box display='flex' justifyContent='center' alignItems='center'>
                                        <Icon as={linkIcon} w="15px" h="15px" />
                                        <Text px={2} fontSize="14px" fontWeight="500">{link.link}</Text>
                                    </Box>
                                </VStack>
                            </WrapItem>
                        ))}
                    </Wrap>
                ) : (
                    <Box display='flex' justifyContent='center' alignItems='center' h='30px'>
                        <Text fontSize="16px" fontWeight="500" color="#2E3646">No links are uploaded</Text>
                    </Box>
                )}
            </Box>
            <br/>
            </Box>
            </Box>
            <Box display='flex' justifyContent='flex-end' py={4} px={5} w='100%' bg='white' borderTop='1px solid #D1E0FF'>
                <Button bg="#2970FF" color='white' variant='solid' width={{ base: 'fit-content', md: 'fit-content', lg: '25%', xl: '20%' }} _hover={{ bg: '#7044c4' }} onClick={handleSubmit}>
                    {isLoading ? <Spinner size="sm" mr="2" /> : null} Generate Report
                </Button>
            </Box>
        </Box>
      </ChakraProvider>
    </>
  );
}

export default Generatereport;
