import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChakraProvider, Box, Text, VStack, IconButton, Icon, useDisclosure, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton } from '@chakra-ui/react';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { ReactComponent as LogoutIcon } from '../../icons/Logout.svg';
import { ReactComponent as HomeIcon } from '../../icons/homeIcon.svg';
import { ReactComponent as createAssessment } from '../../icons/createAssessment.svg';
import { ReactComponent as profileIcon } from '../../icons/profileIcon.svg';
import { ReactComponent as user } from '../../icons/user.svg';
import { ReactComponent as feedback } from '../../icons/feedback.svg';
import { ReactComponent as reportManagement } from '../../icons/reportManagement.svg';
import { ReactComponent as reports } from '../../icons/reports.svg';
import { ReactComponent as assessmentFindings } from '../../icons/assessmentFindings.svg';
import { ReactComponent as techIndicators } from '../../icons/techIndicators.svg';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../../AuthContext';
import useAdminCheck from '../../useAdminCheck';
import MenuItem from './menuitem';

const Sidebar = ({ isCollapsed, toggleSidebar, toggleSubSidebar, toggleSubSidebarOthers }) => {
    const { logout } = useAuth();
    const { isAdmin, userId } = useAdminCheck();
    const navigate = useNavigate();
    const location = useLocation();
    const [selected, setSelected] = useState(() => parseInt(localStorage.getItem('selectedMenuIndex')) || 0);
    const [selectedsub, setSelectedsub] = useState(() => parseInt(localStorage.getItem('selectedSubMenuIndex')) || 0);
    const [selectedsub1, setSelectedsub1] = useState(() => parseInt(localStorage.getItem('selectedNestedMenuIndex')) || 0);
    const [reportSubMenuVisible, setReportSubMenuVisible] = useState(() => localStorage.getItem('reportSubMenuVisible') === 'true');
    const [techIndicatorsSubMenuVisible, setTechIndicatorsSubMenuVisible] = useState(() => localStorage.getItem('techIndicatorsSubMenuVisible') === 'true');
    const { isOpen, onOpen, onClose } = useDisclosure();
  
    const updateSelectedMenu = (path) => {
      if (path === '/assessment' || path === '/upload' || path === '/generateReport') {
        setSelected(1);
        setReportSubMenuVisible(false)
      }else if (path === '/user' || path === '/createUser' || path.startsWith('/editUser')) {
        setSelected(4);
        setReportSubMenuVisible(false)
      }else if (path === '/admintools') {
        setSelected(3);
        setReportSubMenuVisible(false)
      } else if (path.startsWith('/profile')) {
        isAdmin ? setSelected(5) : setSelected(3);      
        setReportSubMenuVisible(false)
      } else if (path.startsWith('/feedback')) {
        isAdmin ? setSelected(6) : setSelected(4);
        setReportSubMenuVisible(false)
      } else if (path.startsWith('/reports')) {
        setSelected(2);
        setSelectedsub(0);
        setReportSubMenuVisible(true);
        window.innerWidth <= 991 ? toggleSubSidebarOthers() : toggleSubSidebar();
      } else if (path.startsWith('/keyFindings')) {
        setSelected(2);
        setSelectedsub(1);
      } else if (path.startsWith('/esgStrategy') || path.startsWith('/businessEthics') || path.startsWith('/humanCapital') || path.startsWith('/supplyChain') || path.startsWith('/dataSecurity')) {
        setSelected(2);
        setSelectedsub(2);
        const subRoute = path.split('/').pop();
        const nestedIndex = ['esgStrategy', 'businessEthics', 'humanCapital', 'supplyChain', 'dataSecurity'].indexOf(subRoute);
        setSelectedsub1(nestedIndex);
      } else {
        setSelected(0);
      }
    };

    // useEffect(() => {
    //     const handleResize = () => {
    //         if (location.pathname.startsWith('/reports')) {
    //             window.innerWidth <= 991 ? toggleSubSidebarOthers() : toggleSubSidebar();
    //         }
    //     };
    //     window.addEventListener('resize', handleResize);
    //     handleResize();
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, [location.pathname, toggleSubSidebar, toggleSubSidebarOthers]);

    useEffect(() => {
        const handleResize = () => {
            const pathsToCheck = ['/reports','/esgStrategy','/businessEthics','/humanCapital','/supplyChain','/dataSecurity','/keyFindings'];
            
            const shouldToggle = pathsToCheck.some(path => location.pathname.startsWith(path));

            if (shouldToggle) {
                window.innerWidth <= 991 ? toggleSubSidebarOthers() : toggleSubSidebar();
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [location.pathname, toggleSubSidebar, toggleSubSidebarOthers]);
  
    useEffect(() => {
      updateSelectedMenu(location.pathname);
    }, [location.pathname]);
  
    useEffect(() => {
      localStorage.setItem('selectedMenuIndex', selected);
    }, [selected]);
  
    useEffect(() => {
      localStorage.setItem('selectedSubMenuIndex', selectedsub);
    }, [selectedsub]);
  
    useEffect(() => {
      localStorage.setItem('selectedNestedMenuIndex', selectedsub1);
    }, [selectedsub1]);
  
    useEffect(() => {
      localStorage.setItem('reportSubMenuVisible', reportSubMenuVisible);
    }, [reportSubMenuVisible]);
  
    useEffect(() => {
      localStorage.setItem('techIndicatorsSubMenuVisible', techIndicatorsSubMenuVisible);
    }, [techIndicatorsSubMenuVisible]);


    const menuItems = [
        { text: 'Homepage', route: '/pastAssessment', icon: HomeIcon },
        { text: 'Create Assessments', route: '/assessment', icon: createAssessment },
        { text: 'Report Management', route: '#', icon: reportManagement, hasSubMenu: true, isSubMenuReport: true },
        ...(isAdmin ? [{ text: 'Admin Tools', route: '/admintools', icon: reportManagement }] : []),
        ...(isAdmin ? [{ text: 'User Management', route: '/user', icon: user }] : []),
        { text: 'My profile', route: '/profile', icon: profileIcon },
        { text: 'Feedback', route: '/feedback', icon: feedback },
    ];

    const reportSubMenuItems = [
        { text: 'Reports', route: '/reports', icon: reports },
        { text: 'Key Findings', route: '/keyFindings', icon: assessmentFindings },
        {  text: 'Tech Indicators', route: '#', icon: techIndicators, hasSubMenu: true, isSubMenuTech: true, subMenu: [
                { text: 'ESG Strategy', route: '/esgStrategy' },
                { text: 'Business Ethics', route: '/businessEthics' },
                { text: 'Human Capital', route: '/humanCapital' },
                { text: 'Supply Chain', route: '/supplyChain' },
                { text: 'Data Security and Privacy', route: '/dataSecurity' }
            ]
        },
    ];

    const handleMenuItemClick = (index, item) => {
        setSelected(index);
        if (item.hasSubMenu) {
            toggleSubSidebar();
            setReportSubMenuVisible(true);
            setSelectedsub(0);
            setReportSubMenuVisible(!reportSubMenuVisible)
            // navigate('/reports');
        } else {
            toggleSubSidebarOthers();
            navigate(item.route);
            setReportSubMenuVisible(false);
            setTechIndicatorsSubMenuVisible(false);
            onClose();
        }
    };

    const handleSubMenuClick = (subIndex, subItem) => {
        setSelectedsub(subIndex);
        if (subItem.hasSubMenu) {
            setTechIndicatorsSubMenuVisible(!techIndicatorsSubMenuVisible);
            setSelectedsub1(0);
            // navigate('/esg-strategy');
        } else {
            navigate(subItem.route);
            setTechIndicatorsSubMenuVisible(false);
            onClose();
        }
    };

    const handleNestedMenuClick = (nestedIndex, nestedItem) => {
        setSelectedsub1(nestedIndex);
        navigate(nestedItem.route);
        onClose();
    };

    return (
        <ChakraProvider>
            <Box display={{ base: 'none', lg: 'block' }} bg='#F5F8FF' w={isCollapsed ? '70px' : '255px'} h='100%' py={4} borderRight='2px solid #F5F8FF' position={'fixed'} top={0} left={0} transition='width 0.3s ease'>
                <Box display='flex' justifyContent='space-between' px={3} pt={2}>
                    {!isCollapsed && <Box cursor='pointer' onClick={()=>navigate("/pastAssessment")}><Text color='Black' fontWeight="medium" fontSize='20px' isTruncated>MSS</Text></Box>}
                    <IconButton
                        aria-label="Toggle Sidebar"
                        icon={isCollapsed ? <LastPageIcon style={{ color: '#004EEB' }} /> : <FirstPageIcon style={{ color: '#004EEB' }} />}
                        onClick={toggleSidebar}
                        variant="ghost"
                        size="sm"
                    />
                </Box>
                <br />
                <Box w='100%' h='90%' display='flex' flexDirection='column' justifyContent='space-between'>
                    <Box p={!isCollapsed ? '20px' : '10px'}>
                        <VStack align='stretch' spacing={2}>
                            {menuItems.map((item, index) => (
                                <MenuItem
                                    key={item.text}
                                    text={item.text}
                                    icon={item.icon}
                                    isSelected={selected === index}
                                    onClick={() => {
                                        setSelected(index);
                                        if (item.hasSubMenu) {
                                            toggleSubSidebar();
                                            setReportSubMenuVisible(true);
                                            setSelectedsub(0);
                                            setReportSubMenuVisible(true)
                                            navigate('/reports');
                                        } else {
                                            toggleSubSidebarOthers();
                                            navigate(item.route);
                                            setReportSubMenuVisible(false);
                                            setTechIndicatorsSubMenuVisible(false);
                                            onClose();
                                        }
                                    }}
                                    isCollapsed={isCollapsed}
                                    hasSubMenu={item.hasSubMenu}
                                    isSubMenuOpen={reportSubMenuVisible}
                                />
                            ))}
                        </VStack>
                    </Box>
                    <Box p={isCollapsed ? '9px' : 5} >
                        <Box as='button' border='2px solid #FECDCA' borderRadius='16px' mb={1} width='fit-content' backgroundColor={'white'} _hover={{ bg: '#fEE4E2' }} _active={{ bg: '#fEE4E2' }}>
                            <Box py={isCollapsed ? 3 : 2} px={isCollapsed ? 3 : 6} display='flex' justifyContent='start' alignItems='center' onClick={() => logout()}>
                                <Icon as={LogoutIcon} width="20px" height="20px" fill="#F04438" />
                                {!isCollapsed && <Text pl={2} color='#F04438' fontSize='14px' fontWeight="medium"> Logout</Text>}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {reportSubMenuVisible && (
                    <Box bg='#EFF4FF' w={isCollapsed ? '250px' : '255px'} h='100%' py={4} borderRight='2px solid #F5F8FF' position={'fixed'} top={0} left={isCollapsed ? '70px' : '255px'} transition='left 0.3s ease, width 0.3s ease'>
                        <Box p='20px'>
                            <VStack align='stretch'>
                                {reportSubMenuItems.map((subItem, subIndex) => (
                                    <React.Fragment key={subItem.text}>
                                        <MenuItem
                                            text={subItem.text}
                                            icon={subItem.icon}
                                            isSelected={selectedsub === subIndex}
                                            onClick={() => {
                                                setSelectedsub(subIndex);
                                                if (subItem.hasSubMenu) {
                                                    setTechIndicatorsSubMenuVisible(!techIndicatorsSubMenuVisible);
                                                    setSelectedsub1(0);
                                                    navigate('/esgStrategy');
                                                } else {
                                                    navigate(subItem.route);
                                                    setTechIndicatorsSubMenuVisible(false);
                                                    onClose();
                                                }
                                            }}
                                            hasSubMenu={subItem.hasSubMenu}
                                            isSubMenuOpen={techIndicatorsSubMenuVisible}
                                            isSubMenuTech={subItem.isSubMenuTech}
                                            selectedsub={selectedsub}
                                            selectedarrow={selectedsub === 2}
                                        />
                                        {subItem.hasSubMenu && techIndicatorsSubMenuVisible && (
                                            <VStack align='stretch' pl={isCollapsed ? 0 : 0}>
                                                {subItem.subMenu.map((nestedItem, nestedIndex) => (
                                                    <MenuItem
                                                        key={nestedItem.text}
                                                        text={nestedItem.text}
                                                        isSelectedc={selectedsub1 === nestedIndex}
                                                        onClick={() => {
                                                            handleNestedMenuClick(nestedIndex, nestedItem);
                                                        }}
                                                    />
                                                ))}
                                            </VStack>
                                        )}
                                    </React.Fragment>
                                ))}
                            </VStack>
                        </Box>
                    </Box>
                )}
            </Box>
            <Box bg='#F5F8FF' w='100%' py={2} px={4} borderBottom='1px solid #E2E8F0' position='fixed' top={0} left={0} zIndex='999' height="48px" display={{ base: 'flex', lg: 'none' }} alignItems='center' justifyContent='flex-start'>
                <IconButton
                    aria-label="Open Menu"
                    icon={<MenuIcon />}
                    onClick={onOpen}
                    variant="ghost"
                    size="lg"
                    style={{ color: '#5A5A5A', fontSize: '30px' }}
                />
                <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                    <DrawerOverlay>
                        <DrawerContent>
                            <DrawerCloseButton color='#2970FF' />
                            <DrawerHeader>MSS</DrawerHeader>
                            <DrawerBody>
                                <VStack align="stretch">
                                {menuItems.map((item, index) => (
                                    <React.Fragment key={item.text}>
                                    <MenuItem
                                        text={item.text}
                                        icon={item.icon}
                                        isSelected={selected === index}
                                        onClick={() => handleMenuItemClick(index, item)}
                                        isCollapsed={false}
                                        hasSubMenu={item.hasSubMenu}
                                        isSubMenuOpen={reportSubMenuVisible}
                                        isSubMenuReport={item.isSubMenuReport}
                                        selectedarrowR={selected === 2}
                                    />
                                    {item.hasSubMenu && reportSubMenuVisible && (
                                        <VStack align="stretch" pl={4}>
                                        {reportSubMenuItems.map((subItem, subIndex) => (
                                            <React.Fragment key={subItem.text}>
                                            <MenuItem
                                                text={subItem.text}
                                                icon={subItem.icon}
                                                isSelected={selectedsub === subIndex}
                                                onClick={() => handleSubMenuClick(subIndex, subItem)}
                                                hasSubMenu={subItem.hasSubMenu}
                                                isSubMenuOpen={techIndicatorsSubMenuVisible}
                                                isSubMenuTech={subItem.isSubMenuTech}
                                                selectedsub={selectedsub}
                                                selectedarrow={selectedsub === 2}
                                            />
                                            {subItem.hasSubMenu && techIndicatorsSubMenuVisible && (
                                                <VStack align="stretch" pl={8}>
                                                {subItem.subMenu.map((nestedItem, nestedIndex) => (
                                                    <MenuItem
                                                    key={nestedItem.text}
                                                    text={nestedItem.text}
                                                    isSelectedc={selectedsub1 === nestedIndex}
                                                    onClick={() => handleNestedMenuClick(nestedIndex, nestedItem)}
                                                    />
                                                ))}
                                                </VStack>
                                            )}
                                            </React.Fragment>
                                        ))}
                                        </VStack>
                                    )}
                                    </React.Fragment>
                                ))}
                                </VStack>
                            </DrawerBody>
                            <DrawerFooter display='flex' justifyContent='start' alignItems='center'>
                                <Box as='button' border='2px solid #FECDCA' borderRadius='16px' mb={1} width='fit-content' backgroundColor={'white'} _hover={{ bg: '#fEE4E2' }} _active={{ bg: '#fEE4E2' }} onClick={() => { logout(); onClose(); }}>
                                    <Box py={2} px={6} display='flex' justifyContent='start' alignItems='center'>
                                        <Icon as={LogoutIcon} width="20px" height="20px" fill="#F04438" />
                                        <Text pl={3} color='#F04438' fontSize='14px' fontWeight="medium"> Logout</Text>
                                    </Box>
                                </Box>
                            </DrawerFooter>
                        </DrawerContent>
                    </DrawerOverlay>
                </Drawer>
            </Box>
        </ChakraProvider>
    );
};

export default Sidebar;
