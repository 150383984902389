import '../../App.css';
import React from "react";
import { ChakraProvider } from '@chakra-ui/react';
import { Input, Button, Text, Box as ChakraBox, Heading, useToast, Spinner } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Resetpassword({ setEmail }) {
  const [email, setEmailreset] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isOtpGenerated, setIsOtpGenerated] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  const toast = useToast();
  const navigate = useNavigate();

  const validate = (field, value) => {
    let error = '';

    switch (field) {
      case 'email':
        const emailRegex = /^(?!.*(\.\.|@\.|\.@|@-))^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!value) {
          error = 'Email is required';
        } else if (!emailRegex.test(value)) {
          error = 'Email is invalid';
        }
        break;
      default:
        break;
    }

    return error;
  };

  const handleBlur = (field) => (e) => {
    const error = validate(field, e.target.value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));
  };

  const handleSubmit = async () => {
    const fields = ['email'];
    const validationErrors = {};

    // Validate individual fields
    fields.forEach((field) => {
      const value = eval(field);
      const error = validate(field, value);
      if (error) {
        validationErrors[field] = error;
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // Perform the submit action
      const data = { emailId: email };
      setIsLoading(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/forgot-password`, data);
        setIsOtpGenerated(true);
        setEmail(email);
        setTimeout(() => {
          setEmailreset('');
          navigate('/otp');
        }, 1000);
      } catch (error) {
        console.error('Error submitting form:', error);
        if (error.response && error.response.data.message === "No account found for this email address") {
          setErrors({ email: "No account found for this email" });
          setIsOtpGenerated(false);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="login">
      <div className='rounded-box'>
        <ChakraProvider>
          <Heading as="h1" fontWeight='700' fontSize='24px' color={'black'}>Reset Password</Heading>
          <br />
          <Text as="h6" fontWeight='500' fontSize='16px' color={'#00359E'} mb='2' >Please enter your email address to reset your password </Text>
          <br />
          <ChakraBox textAlign={'left'} width={'100%'} >
            <ChakraBox mb="8">
              <Text fontSize='14px' fontWeight='500' color='#111927' mb="2">
                Email
              </Text>
              <Input
                variant='outline'
                bg='white'
                focusBorderColor={errors.email ? '#F04438' : isOtpGenerated ? '#12B76A' : '#2970FF'}
                placeholder='Enter your Email address'
                size='md'
                value={email}
                onChange={(e) => setEmailreset(e.target.value)}
                onBlur={handleBlur('email')}
                borderColor={errors.email ? '#F04438' : isOtpGenerated ? '#12B76A' : '#F3F4F6'}
                sx={{
                  '::placeholder': {
                    color: '#9DA4AE',
                  },
                }}
              />
              {errors.email && <Text color="#F04438" fontSize="16px" fontWeight='400' >{errors.email}</Text>}
              {isOtpGenerated && <Text color="#12B76A" fontSize="16px" fontWeight='400' >OTP successfully sent to your email</Text>}
            </ChakraBox>
            <Button bg="#2970FF" color='white' variant='solid' width={'100%'} _hover={{ bg: '#7044c4' }} onClick={handleSubmit}>
              {isLoading ? <Spinner size="sm" mr="2" /> : null}  Send OTP
            </Button>
            <ChakraBox mt="5">
              <Text textAlign='center' bg={'#EFF4FF'} fontSize='14px' fontWeight="400" color='#000000' cursor='pointer' onClick={() => navigate('/login')}>
                Back to <span style={{ color:'#00359E' }}> signIn </span>
              </Text> 
            </ChakraBox>
          </ChakraBox>
        </ChakraProvider>
      </div>
    </div>
  );
}

export default Resetpassword;
