import React, { useState, useEffect } from 'react';
import {
  ChakraProvider, Table, Thead, Tbody, Tr, Th, Td, Box, TableContainer, Button, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Switch, Icon, Tooltip, useToast
} from '@chakra-ui/react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { CheckIcon } from '@chakra-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { get, patch, remove } from '../../api';
import { ReactComponent as EyeIcon } from '../../icons/EyeIcon.svg';
import { ReactComponent as DeleteIcon } from '../../icons/deleteIcon.svg';

const muiTheme = createTheme();

function UserlistTableComponent({ searchTerm, sortOption = { sortBy: 'userName', sortOrder: 'asc', status: '', role: '' }, selectedItems, currentPage, setCurrentPage, updateNavigateState }) {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const pageSize = 10;
  const [userData, setUserData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState(sortOption.sortOrder || 'asc');
  const [selectedSortBy, setSelectedSortBy] = useState(sortOption.sortBy || 'userName');

  const fetchUserDetails = async (page, searchTerm, sortBy, order, status = [], role = []) => {
    try {
      const response = await get('users/user/list', {
        params: {
          page: page,
          limit: pageSize,
          query: searchTerm,
          sortBy: sortBy,
          sortOrder: order,
          sortStatus: status,
          sortRole: role
        }
      });
      setUserData(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching user list:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    fetchUserDetails(newPage, searchTerm, selectedSortBy, selectedOrder, sortOption.status, sortOption.role);
    updateNavigateState(newPage, searchTerm, sortOption, selectedItems);
  };

  const handleStatusChange = async (userId) => {
    try {
      const updatedUser = userData.find(user => user.userId === userId);
      const newStatus = updatedUser.status === 'active' ? 'inactive' : 'active';

      await patch(`users/${userId}`, { status: newStatus });

      const updatedData = userData.map(user => {
        if (user.userId === userId) {
          return { ...user, status: newStatus };
        }
        return user;
      });

      setUserData(updatedData);
    } catch (error) {
      console.error('Error updating user status:', error);
    }
  };

  const viewProfile = (userId) => {
    navigate(`/editUser/${userId}`, { state: { currentPage, searchTerm, sortOption, selectedItems } });
  };

  const deleteProfile = async (userId) => {
    try {
      const response = await remove(`users/${userId}`);

      toast({
        title: 'User has been deleted successfully',
        description: 'User Deleted',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
      fetchUserDetails(currentPage, searchTerm, selectedSortBy, selectedOrder, sortOption.status, sortOption.role);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }

  useEffect(() => {
    fetchUserDetails(currentPage, searchTerm, selectedSortBy, selectedOrder, sortOption.status, sortOption.role);
  }, [selectedSortBy, selectedOrder, searchTerm, sortOption, currentPage]);

  const handleSortChange = (sortBy, order) => {
    setSelectedOrder(order);
    setSelectedSortBy(sortBy);
    setCurrentPage(1);
    updateNavigateState(1, searchTerm, { ...sortOption, sortBy, sortOrder: order }, selectedItems);
    fetchUserDetails(1, searchTerm, sortBy, order, sortOption.status, sortOption.role);
  };

  return (
    <ChakraProvider>
      <Box width="100%" py={4}>
        <TableContainer width="full" border="1px solid" borderColor="#D1E0FF" borderRadius="xl">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th py={4} borderColor="#D1E0FF" textTransform="none" colSpan={5} color='black' fontSize={'16px'} backgroundColor={'#F5F8FF'}>User List</Th>
                <Th py={3} borderColor="#D1E0FF" backgroundColor={'#F5F8FF'} display={'flex'} justifyContent={'flex-end'}>
                  <Menu>
                    <MenuButton as={Button} rightIcon={<FilterListOutlinedIcon />} 
                        bg="#D1E0FF"
                        color="#00395E"
                        _hover={{ bg: "#c5d7fc" }}
                        _active={{ bg: "#c5d7fc" }}
                        _focus={{ bg: "#c5d7fc" }}
                        textAlign="right"
                        h="38px" >
                        Sort
                    </MenuButton>
                    <MenuList width="fit-content" minWidth="0" p={4} borderColor="#EFF4FF" borderRadius="12px">
                      <MenuItem borderRadius="5px" bg={selectedOrder === 'asc' ? "#EFF4FF" :"white"} _hover={{ bg: "#EFF4FF" }} color="#00359E" onClick={() => handleSortChange('userName', 'asc')}>
                        Alphabetical Asc. (A to Z)
                        {selectedOrder === 'asc' && (
                          <Box as="span" color="#00359E" ml={4}>
                            <CheckIcon boxSize={3} />
                          </Box>
                        )}
                      </MenuItem>
                      <MenuDivider borderWidth="1px" borderColor="#D1E0FF" />
                      <MenuItem borderRadius="5px" bg={selectedOrder === 'desc' ? "#EFF4FF" :"white"} _hover={{ bg: "#EFF4FF" }} color="#00359E" onClick={() => handleSortChange('userName', 'desc')}>
                        Alphabetical Des. (Z to A)
                        {selectedOrder === 'desc' && (
                          <Box as="span" color="#00359E" ml={4}>
                            <CheckIcon boxSize={3} />
                          </Box>
                        )}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Th>
              </Tr>
            </Thead>
            <Thead>
              <Tr>
                <Th py={4} fontSize='14px' borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor='#F5F8FF' width='16%' textTransform="none">User Name
                  <style jsx>{`
                    .username-text {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-word;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                      }
                    `}
                  </style>
                </Th>
                <Th py={4} fontSize='14px' verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor='#F5F8FF' width='20%' textTransform="none">Email Address
                  <style jsx>{`
                    .email-text {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-word;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                      }
                    `}
                  </style>
                </Th>
                <Th py={4} fontSize='14px' verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor='#F5F8FF' width='16%' textTransform="none">Company name
                  <style jsx>{`
                    .company-text {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-word;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                      }
                    `}
                  </style>
                </Th>
                <Th py={4} fontSize='14px' verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor='#F5F8FF' width='16%' textTransform="none">Role</Th>
                <Th py={4} fontSize='14px' verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor='#F5F8FF' width='16%' textTransform="none">Status</Th>
                <Th py={4} fontSize='14px' verticalAlign="middle" textAlign="center" borderColor="#D1E0FF"  color='black' backgroundColor='#F5F8FF' width='16%' textTransform="none">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {userData.map(item => (
                <Tr key={item.userId}>
                  <Td borderRight="1px solid" borderColor="#D1E0FF" fontSize='14px' fontWeight='500' color='#6C737F' width='16%'>
                    <div className="username-text">{item.userName}</div>
                  </Td>
                  <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" fontSize='14px' fontWeight='500' color='#6C737F' width='20%'>
                    <a href={`mailto:${item.emailId}`} style={{ textDecoration: 'none', color: '#6C737F' }}  onMouseOver={(e) => e.currentTarget.style.color = '#00359E'} onMouseOut={(e) => e.currentTarget.style.color = '#6C737F'}>
                      <div className="email-text">{item.emailId}</div>
                    </a>
                  </Td>
                  <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" fontSize='14px' fontWeight='500' color='#6C737F' width='16%'>
                    <div className="company-text">{item.companyName}</div>
                  </Td>
                  <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" fontSize='14px' fontWeight='500' color='#6C737F' width='16%'>{item.role}</Td>
                  <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" fontSize='14px' fontWeight='500' color='#6C737F' width='16%'>
                    <Switch size='lg' colorScheme='green' isChecked={item.status === 'active'} onChange={() => handleStatusChange(item.userId)} />
                  </Td>
                  <Td verticalAlign="middle" textAlign="center" borderColor="#D1E0FF" width='20%'>
                    <Box width='100%' as='button' display='flex' fontSize='14px' fontWeight='400' justifyContent='center' alignItems='center' gap={3}>
                      <Tooltip label='View & Edit Account' placement='top' hasArrow arrowSize={9}>
                        <Icon as={EyeIcon} w={7} h={5} color='black'  onClick={() => viewProfile(item.userId)}/>
                      </Tooltip>
                      <Tooltip label='Delete Account' placement='top'hasArrow arrowSize={9} >
                        <Icon as={DeleteIcon} w={7} h={4} color='black' onClick={() => deleteProfile(item.userId)}/>
                      </Tooltip>
                      {/* View Profile */}
                    </Box>
                  </Td>
                </Tr>
              ))}
              <Tr>
                <Th colSpan={7} verticalAlign="middle" textAlign={'center'} backgroundColor={'#F5F8FF'}>
                  <ThemeProvider theme={muiTheme}>
                    <Box w='100%' display="flex" justifyContent="center" alignItems="center">
                      <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                        sx={{
                          '& .MuiPaginationItem-root': {
                            borderColor: '#D1E0FF',
                            backgroundColor: 'white',
                            color: 'black',
                            '&:hover': {
                              backgroundColor: 'white',
                              color: '#2970FF',
                              borderColor: '#2970FF',
                            },
                            '&.Mui-selected': {
                              backgroundColor: 'white',
                              color: '#2970FF',
                              borderColor: '#2970FF',
                            }
                          },
                          '& .MuiPaginationItem-previousNext': {
                            backgroundColor: '#F5F8FF',
                            borderColor: '#D1E0FF',
                            color: '#9E9E9E',
                            '&:hover': {
                              backgroundColor: '#F5F8FF',
                              borderColor: '#2970FF',
                              color: '#2970FF',
                            },
                            '&.Mui-selected': {
                              backgroundColor: '#F5F8FF',
                              color: '#2970FF',
                              borderColor: '#2970FF',
                            },
                            '&.Mui-disabled': {
                              backgroundColor: '#6C737F',
                              color: '#EEEEEE',
                              borderColor: '#6C737F',
                            }
                          }
                        }}
                      />
                    </Box>
                  </ThemeProvider>
                </Th>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </ChakraProvider>
  );
}

export default UserlistTableComponent;
