import '../../App.css';
import React from "react";
import { ChakraProvider } from '@chakra-ui/react'
import { Input, InputGroup, InputRightElement, Button, Text, Box as ChakraBox, Heading, useToast, Spinner } from '@chakra-ui/react'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Newpassword({ email }) {
  const [show, setShow] = React.useState(false)
  const [show1, setShow1] = React.useState(false)
  const [password, setPassword] = React.useState('')
  const [confirmpassword, setConfirmpassword] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false);

  const [errors, setErrors] = React.useState({});

  const toast = useToast()
  const navigate = useNavigate();


  const handleClick = () => setShow(!show)
  const handleClick1 = () => setShow1(!show1)

  const validate = (field, value) => {
    let error = '';

    switch (field) {
      case 'password':
        if (!value) {
          error = 'Password is required';
        } else if (value.length < 6) {
          error = 'Password must be at least 6 characters';
        }
        break;
      case 'confirmpassword':
        if (!value) {
          error = 'Confirm password is required';
        } else if (value !== password) {
          error = 'Passwords do not match';
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));

    return error;
  };

  const handleBlur = (field) => (e) => {
    validate(field, e.target.value);
  };

  const handleSubmit = async () => {
    const fields = [
      'password',
      'confirmpassword',
    ];

    const validationErrors = {};

    // Validate individual fields
    fields.forEach((field) => {
      const value = eval(field);
      const error = validate(field, value);
      if (error) {
        validationErrors[field] = error;
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const data = {
      emailId: email,
      password: password,
    };
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/reset-password`, data);
      setPassword('');
      setConfirmpassword('');
      navigate('/login', { state: { success: true } });

    } catch (error) {
      console.error('Error submitting form:', error);
      setIsLoading(false);
      if (error.response && error.response.data.message === "OTP verification required") {
        setErrors({ confirmpassword: 'OTP verification required to reset Password' });
      }
    }
};

  // React.useEffect(() => {
  //   const handlePopState = (event) => {
  //     navigate(1);
  //   };

  //   window.history.pushState(null, null, window.location.pathname);
  //   window.addEventListener('popstate', handlePopState);

  //   return () => {
  //     window.removeEventListener('popstate', handlePopState);
  //   };
  // }, [navigate]);

  return (
    <div className="login">
      <div className='rounded-box'>
        <ChakraProvider>
          <Heading as="h1" fontWeight='700' fontSize='24px' color={'black'}>Enter New Password</Heading>
          <br />
          <Text as="h6" fontWeight='500' fontSize='16px' color={'#00359E'}>Reset your password by entering the new password below</Text>
          <br />
          <ChakraBox textAlign={'left'} width={'100%'}>
            <ChakraBox mb="8">
              <Text fontSize='14px' fontWeight='500' color='#111927' mb="2">
                New Password
              </Text>
              <InputGroup size='md'>
                <Input
                  pr='4.5rem'
                  type={show ? 'text' : 'password'}
                  placeholder='Enter your new password'
                  bg='white'
                  focusBorderColor={errors.password ? '#F04438' : '#2970FF'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={handleBlur('password')}
                  borderColor={errors.password ? '#F04438' : '#F3F4F6'}
                  sx={{
                    '::placeholder': {
                      color: '#9DA4AE',
                    },
                  }}
                />
                <InputRightElement width='4.5rem'>
                  <Button h='1.75rem' size='sm' bg={'white'} _hover={{ bg: 'white' }} onClick={handleClick}>
                    {show ? <VisibilityOutlinedIcon sx={{color:'#9DA4AE'}} boxSize={5} /> : <VisibilityOffOutlinedIcon sx={{color:'#9DA4AE'}} boxSize={5} />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {errors.password && <Text color="#F04438" fontSize="16px" fontWeight='400'>{errors.password}</Text>}
            </ChakraBox>
            <ChakraBox mb="2">
              <Text fontSize='14px' fontWeight='500' color='#111927' mb="2">
                Confirm New Password
              </Text>
              <InputGroup size='md'>
                <Input
                  pr='4.5rem'
                  type={show1 ? 'text' : 'password'}
                  placeholder='Confirm your new password'
                  bg='white'
                  focusBorderColor={errors.confirmpassword ? '#F04438' : '#2970FF'}
                  value={confirmpassword}
                  onChange={(e) => setConfirmpassword(e.target.value)}
                  onBlur={handleBlur('confirmpassword')}
                  borderColor={errors.confirmpassword ? '#F04438' : '#F3F4F6'}
                  sx={{
                    '::placeholder': {
                      color: '#9DA4AE',
                    },
                  }}
                />
                <InputRightElement width='4.5rem'>
                  <Button h='1.75rem' size='sm' bg={'white'} _hover={{ bg: 'white' }} onClick={handleClick1}>
                    {show1 ? <VisibilityOutlinedIcon sx={{color:'#9DA4AE'}} boxSize={5} /> : <VisibilityOffOutlinedIcon sx={{color:'#9DA4AE'}} boxSize={5} />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {errors.confirmpassword && <Text color="#F04438" fontSize="16px" fontWeight='400'>{errors.confirmpassword}</Text>}
            </ChakraBox>
            <br />
            <Button bg="#2970FF" color='white' variant='solid' width={'100%'} _hover={{ bg: '#7044c4' }} onClick={handleSubmit}>
              {isLoading ? <Spinner size="sm" mr="2" /> : null}  Reset Password
            </Button>
            <ChakraBox mt="5">
              <Text textAlign='center' bg={'#EFF4FF'} fontSize='14px' fontWeight="400" color='#000000' cursor='pointer' onClick={() => navigate('/login')}>
                Back to <span style={{ color:'#00359E' }}> signIn </span>
              </Text> 
            </ChakraBox>
          </ChakraBox>
        </ChakraProvider>
      </div>
    </div>
  );
}

export default Newpassword;
