import '../../App.css';
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import {ChakraProvider, Text, Box, Heading, Button, IconButton, Icon, Flex, useToast} from '@chakra-ui/react';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import SmalltableComponent from '../../components/table/smalltable';
import { ReactComponent as companyIcon } from '../../icons/companyIcon.svg'
import useAdminCheck from '../../useAdminCheck';
import { post } from '../../api';

function Assessment() {
  const navigate = useNavigate();
  const toast = useToast();
  const { username, companyName, userId, isAdmin } = useAdminCheck();

  const createAssessment = async () => {
    try {
      const response = await post(`assessment/${userId}/assessment`);

      // toast({
      //   title: response.data.message,
      //   description: 'Assessment created upload ',
      //   status: 'success',
      //   duration: 5000,
      //   isClosable: true,
      //   position: 'top-right',
      // });
      navigate('/upload', { state: { assessmentId: response.data.data.assessmentId  } });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast({
        title: 'Error',
        description: error.response ? error.response.data.message : 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  return (
    <>
      {/* <Sidebar/> */}
      <ChakraProvider>
        <Box px={10} pt={10} w='100%' h='fit-content' overflow-y='scroll'>
            <Box display='flex' flexDirection={{ base: 'column', md: 'row' }} justifyContent={{ base:'center', md:'space-between'}} alignItems={{ base:'flex-start', md:'center'}}>
                <Heading size='md' fontSize='24px' fontWeight={700}  mb={1} >Welcome {username}!</Heading>
                <Box px={{base:'12px', md:'15px'}} py={{base:'12px', md:'15px'}} mt={{base:2}} alignSelf={{ base: 'flex-end'}} border='1px solid #F5F8FF' borderRadius='10px' backgroundColor='#F5F8FF'>
                  <Box display='flex' justifyContent='center' alignItems='center'>
                    <Icon as={companyIcon} w="20px" h="18px"/>
                    <Text pl='5px' fontWeight='400' fontSize='12px' color='#00359E' whiteSpace='nowrap'>Company Name</Text>
                  </Box>
                    <Text fontWeight='600' fontSize='20px' color='#111927' whiteSpace='nowrap'>{companyName}</Text>
                </Box>
            </Box>
            <br/>
            <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                border="2px dashed"
                borderColor="#2970FF"
                borderRadius="lg"
                bg= '#EFF4FF'
                p={4} w="100%" h='230px'
                _hover={{ bg: '#EFF4FF' }}
                cursor="pointer"
                onClick={() => createAssessment()}
              >
                <Box mb={2} px='2px' py='2px' border='1px solid #D1E0FF' backgroundColor='#D1E0FF' borderRadius='5px' width='fit-content' height='fit-content'>
                  <IconButton
                      w='fit-content'
                      h='fit-content'
                      icon={<AddBoxRoundedIcon sx={{fontSize: 40, color:'#2970FF'}} />}
                      _hover={{ bg:'#D1E0FF'}}
                      _active={{ bg:'#D1E0FF'}}
                      aria-label="New Assessment"
                      variant="ghost"
                      size="sm"
                  />
                </Box>
                <Text fontSize="md" fontWeight='medium' color="gray.600">New Assessment</Text>
            </Box>
            <br/>
            <SmalltableComponent userId={userId} isAdmin={isAdmin}/>
        </Box>
      </ChakraProvider>
    </>
  );
}

export default Assessment;
