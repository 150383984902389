import React, { useState, useEffect } from 'react';
import {
  ChakraProvider, Table, Thead, Tbody, Tr, Th, Td, Box, Text, Flex, TableContainer, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Button
} from '@chakra-ui/react';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { useNavigate } from 'react-router-dom';
import { get, post } from '../../api'

const PopoverMenu = ({reportId}) => {
  const navigate = useNavigate();

  const viewReport = async (reportId) =>{
    console.log("View report")
    navigate(`/reports/${reportId}`);
  }

  const downloadReport = async(reportId) => {
    try {
        const response = await post(`report/downloadReport/${reportId}`, {}, { responseType: 'blob' });

        // Create a link element and trigger the download
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report_${reportId}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        console.error('Error downloading report:', error);
    }
  }

  // const downloadReport = async (reportId) => {
  //   try {
  //       const response = await get(`/assessment/${reportId}/report`, {
  //           headers: {
  //               'Content-Type': 'application/json'
  //           }
  //       });

  //       const { filename, fileData, contentType } = response.data;
  //       // Decode base64 to binary
  //       const byteCharacters = atob(fileData);
  //       const byteNumbers = new Array(byteCharacters.length);
  //       for (let i = 0; i < byteCharacters.length; i++) {
  //           byteNumbers[i] = byteCharacters.charCodeAt(i);
  //       }
  //       const byteArray = new Uint8Array(byteNumbers);

  //       const blob = new Blob([byteArray], { type: contentType });
  //       const url = window.URL.createObjectURL(blob);
        
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', filename);
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //       console.error('Error downloading the file:', error);
  //   }
  // };

    return (<>
      <Menu>
        <MenuButton as={Button}
          bg="white"
          color="black"
          height="1.2rem"
          _hover={{ bg: "white" }}
          _active={{ bg: "white" }}
          _focus={{ bg: "white" }}
        >
          <MoreVertRoundedIcon />
        </MenuButton>
        <MenuList width="fit-content" minWidth="0" p={4} borderColor='#EFF4FF' borderRadius='12px'>
          <MenuItem borderRadius='5px' bg="white" _hover={{ bg: "#EFF4FF" }} color='#00359E' onClick={() => viewReport(reportId)}>View Report</MenuItem>
          <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
          <MenuItem borderRadius='5px' bg="white" _hover={{ bg: "#EFF4FF" }} color='#00359E' onClick={() => downloadReport(reportId)}> Download Report </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

function SmalltableComponent({ userId, isAdmin }) {

  const navigate = useNavigate();
  const pageSize = 3;
  const [assessmentData, setAssessmentData] = useState([]);

  const fetchUserDetails = async () => {
    try {
      const response = await get(`assessment/${userId}/list`, {
        params: {
          page: 1,
          limit: pageSize,
          sortBy: 'createdAt',
          sortOrder: 'desc',
          isAdmin: isAdmin
        }
      });
      setAssessmentData(response.data.data || []);
    } catch (error) {
      console.error('Error fetching assessment list:', error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [userId, isAdmin]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (assessmentData.some(assessment => assessment.status === 'In Process')) {
        fetchUserDetails();
      }
    }, 300000);

    return () => clearInterval(interval);
  }, [assessmentData])

  const statusColors = {
    "Completed": "#12B76A",
    "In Process": "#FF692E",
  };

  return (
    <ChakraProvider>
      <Box width="100%" py={4}>
        <TableContainer width="full" border="1px solid" borderColor="#D1E0FF" borderRadius="xl">
          <Table variant="simple">
          <Thead >
              <Tr>
              <Th
                py={4}
                borderColor="#D1E0FF" 
                color='black' 
                backgroundColor={'#F5F8FF'}
                colSpan={5}
                w='100%'
                >
                <Flex justifyContent="space-between" alignItems="center" w="100%">
                    <Text  fontSize='18px' textTransform="none">Past Assessment</Text>
                    <Flex alignItems="center" cursor='pointer'>
                        <Text fontWeight='medium' fontSize='16px' color='#004EEB' textTransform="none" onClick={()=>navigate('/pastAssessment')}>View All</Text>
                    </Flex>
                </Flex>
                </Th>
              </Tr>
            </Thead>
            <Thead >
              <Tr >
                <Th py={4} borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} fontWeight='700' fontSize='16px' textTransform="none"> Assessment Name</Th>
                <Th py={4} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} fontWeight='700' fontSize='16px' textTransform="none">ESG Score</Th>
                <Th py={4} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} fontWeight='700' fontSize='16px' textTransform="none">Date</Th>
                <Th py={4} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} fontWeight='700' fontSize='16px' textTransform="none">Status</Th>
                <Th py={4} verticalAlign="middle" textAlign="center" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'}fontWeight='700'  fontSize='16px' textTransform="none">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {assessmentData.map(item => (
                <Tr key={item.assessmentId}>
                  <Td borderRight="1px solid" borderColor="#D1E0FF" color='#6C737F' backgroundColor={'white'} fontWeight='500'>{item.assessmentName}</Td>
                  <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" fontWeight='500' color='#6C737F' backgroundColor={'white'}>{item.esgScore === null ? '-' : item.esgScore}</Td>
                  <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" fontWeight='500' color='#6C737F' backgroundColor={'white'}>{new Date(item.createdAt).toLocaleDateString('en-GB')}</Td>
                  <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color={statusColors[item.status] || "#2970FF"} backgroundColor={'white'}>{item.status}</Td>
                  <Td verticalAlign="middle" textAlign="center" borderColor="#D1E0FF" color={'black'} backgroundColor={'white'}>
                    {/* {item.status === 'Completed' ? <PopoverMenu/> : <Box as='button'><Text fontWeight='500' fontSize='14px' textDecoration='underline' color='#00359E' >Resume</Text></Box>} */}
                    {item.status !== 'Completed' ? '-' :<PopoverMenu reportId={item.reportId}/>}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </ChakraProvider>
  );
}

export default SmalltableComponent;
