import '../../App.css';
import React, { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChakraProvider, Box, Heading, Button, ButtonGroup, Grid, GridItem, IconButton, Text, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Input, InputGroup, InputRightElement, Wrap, WrapItem, VStack, Icon, useToast, Spinner, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ReactComponent as docusIcons } from '../../icons/docusIcons.svg';
import { ReactComponent as dropboxIcons } from '../../icons/dropboxIcons.svg';
import { ReactComponent as circleCloseIcon } from '../../icons/circleCloseIcon.svg';
import DropboxChooser from "react-dropbox-chooser";
import GoogleDrivePicker from "google-drive-picker";
import { useDropzone } from 'react-dropzone';
import { CheckIcon } from '@chakra-ui/icons';
import { patch, get } from '../../api';
import { ReactComponent as fileIcon } from '../../icons/fileIcon.svg';
import { ReactComponent as linkIcon } from '../../icons/linkIcon.svg';

function Upload() {
  const APP_KEY = "7xp4y3eh3i8e08h";
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [files, setFiles] = React.useState([]);
  const [rejectedFiles, setRejectedFiles] = React.useState([]); 
  const [link, setLink] = React.useState('');
  const [addlink, setAddlink] = React.useState([]);
  const [selectedOptionSuggest, setSelectedOptionSuggest] = React.useState('ESG Strategy & Execution');
  const [authTocken, setauthTocken] = React.useState("");
  const [openPicker, authRes] = GoogleDrivePicker();
  const [errors, setErrors] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [menuButtonWidth, setMenuButtonWidth] = React.useState(null);
  const menuButtonRef = React.useRef();
  const menuButtonRef1 = React.useRef();
  const assessmentId = location.state?.assessmentId || '';
  const [selectedOption, setSelectedOption] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [numberOfEmployees, setNumberOfEmployees] = React.useState();
  const [industryOptions, setIndustryOptions] = React.useState([]);

  React.useEffect(() => {
    if (menuButtonRef.current) {
      setMenuButtonWidth(menuButtonRef.current.clientWidth);
    }
  }, [menuButtonRef.current]);

  React.useEffect(() => {
    const fetchIndustryTypes = async () => {
      try {
        const response = await get('/reportmanagement/industry');
          
        const industryTypes = response.data.data.map(item => item.industryType);
        setIndustryOptions(industryTypes);
      } catch (error) {
        console.error('Error fetching industry types:', error);
      }
    };
    
    fetchIndustryTypes();
  }, []);

  const fileInputRef = useRef(null);

  const validate = (field, value) => {
    let error = '';

    switch (field) {
        case 'files':
            if (!value || value.length === 0) {
            error = 'Files are required';
            }
            break;
        case 'addlink':
            if (!value || value.length === 0) {
                error = 'At least one link is required';
            } else {
                const urlPattern = new RegExp('^(https?:\\/\\/)?([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\\/[^\\s]*)?$');
                if (!urlPattern.test(value)) {
                    error = 'Invalid link';
                }
            }
            break;
        case 'companyName':
            const companyNameRegex = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+){0,49}$/;
            if (!value) {
                error = 'Company name is required';
            } else if (!companyNameRegex.test(value)) {
                error = 'Company name is invalid';
            }
            break;
        case 'numberOfEmployees':
            if (!value) {
                error = 'Number of employees is required';
            } else if (isNaN(value)) {
                error = 'Number of employees must be a number';
            } else if (value <= 0) {
                error = 'Number of employees must be a greater than 0';
            }
            break;
        case 'industryType':
            if (value === '') {
                error = 'Industry type is required';
            }
            break;
        default:
            break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));

    return error;
  };

  const handleFileButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const duplicateFiles = [];

    console.log(files)
  
    selectedFiles.forEach((newFile) => {
      const isDuplicate = files.some((file) => file.name === newFile.name && file.size === newFile.size);
      if (isDuplicate) {
        duplicateFiles.push(newFile.name);
      } else {
        setFiles((prevFiles) => [...prevFiles, newFile]);
      }
    });
  
    if (duplicateFiles.length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        files: ` ${duplicateFiles.join(', ')} file is already uploaded`,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        files: ''
      }));
    }
    e.target.value = null;
  };

  const handleGoogleDriveUpload = () => {
    openPicker({
      clientId: "508023560665-6q8m49jcbntmfnc7sf8mv1ousstp3248.apps.googleusercontent.com",
      developerKey: "AIzaSyCguQfzGLPRv6e2xUc2_xHegtVG7ZObNy8",
      viewId: "PDFS",
      token: authTocken,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        } else if (data.docs && data.docs.length > 0) {
          const googleDriveFiles = data.docs.map(doc => ({
            name: doc.name,
            link: doc.url,
            mimeType: doc.mimeType,
            size: doc.sizeBytes
          }));
  
          const duplicateFiles = [];
          const nonDuplicateFiles = [];
  
          googleDriveFiles.forEach((newFile) => {
            const isDuplicate = files.some((file) => file.name === newFile.name && file.size === newFile.size);
            // console.log(isDuplicate)
            if (isDuplicate) {
              duplicateFiles.push(newFile.name);
            } else {
              nonDuplicateFiles.push(newFile);
            }
          });
  
          if (duplicateFiles.length > 0) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              files: ` ${duplicateFiles.join(', ')} file is already uploaded`,
            }));
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              files: ''
            }));
          }
  
          setFiles((prevFiles) => [...prevFiles, ...nonDuplicateFiles]);
        }
      }
    });
  };

  const handleDropboxUpload = (inputFiles) => {
    const dropboxFiles = inputFiles.map(file => ({
      name: file.name,
      link: file.link,
      mimeType: file.mimeType,
      size: file.bytes
    }));
    const duplicateFiles = [];
    const nonDuplicateFiles = [];
  
    dropboxFiles.forEach((newFile) => {
      const isDuplicate = files.some((file) => file.name === newFile.name && file.size === newFile.size);
      if (isDuplicate) {
        duplicateFiles.push(newFile.name);
      } else {
        nonDuplicateFiles.push(newFile);
      }
    });
  
    if (duplicateFiles.length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        files: ` ${duplicateFiles.join(', ')} file is already uploaded`,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        files: ''
      }));
    }
  
    setFiles((prevFiles) => [...prevFiles, ...nonDuplicateFiles]);
  };

  const removelink = (Link) => {
    setAddlink(addlink.filter(link => link !== Link));
  };

  const AddLink = () => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\\/[^\\s]*)?$');
  
    if (link.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        addlink: 'Link is required',
      }));
    } else if (!urlPattern.test(link)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        addlink: 'Invalid link format',
      }));
    } else {
      setAddlink((prevLinks) => [...prevLinks, link]);
      setLink('');
      setErrors((prevErrors) => ({
        ...prevErrors,
        addlink: ''
      }));
    }
  };
  

  const generateReport = async (e) => {
    e.preventDefault();
    navigate(location.pathname, { state: { assessmentId:assessmentId  } });
    const fields = ['files', 'companyName', 'numberOfEmployees', 'industryType',];
    let hasErrors = false;

    fields.forEach((field) => {
        const value = field === 'files' ? files : field === 'companyName' ? companyName : field === 'numberOfEmployees' ? numberOfEmployees : selectedOption;
        const error = validate(field, value);
        if (error) {
            hasErrors = true;
        }
    });

    if (hasErrors) {
      return;
    }

    setIsLoading(true)

    const data = new FormData();
    files.forEach(file => {
      if (!file.fileId) {
        if (file.link) {
          data.append('uploadDocument[]', JSON.stringify(file));
        } else {
          data.append('uploadDocument', file);
        }
      }
    });
    addlink.forEach(link => {
      data.append('links[]', link);
    });

    data.append('companyName', companyName)
    data.append('companySize', parseInt(numberOfEmployees))
    data.append('industryType', selectedOption)

    try {
      const response = await patch(`assessment/${assessmentId}/assessment`, data,  {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      toast({
        title: response.data.message,
        description: 'Assessment has been created successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      setFiles([]);
      setAddlink([]);
      setCompanyName('')
      setNumberOfEmployees()
      setSelectedOption('')
      setTimeout(() => {
         navigate('/assessment');
      }, 1000);
    } catch (error) {
      console.error('Error submitting form:', error);
      toast({
        title: 'Error',
        description: error.response ? error.response.data.message : 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (authRes) {
      setauthTocken(authRes.access_token);
    }
  }, [authRes]);

  const handleLinkChange = (e) => {
    const newLink = e.target.value;
    setLink(newLink);
  
    const urlPattern = new RegExp('^(https?:\\/\\/)?([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\\/[^\\s]*)?$');
  
    if (!urlPattern.test(newLink)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        addlink: 'Invalid Link'
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        addlink: ''
      }));
    }
  };

  const sections = {
    'ESG Strategy & Execution': [
      'Employee Handbook',
      'ESG Strategy'
    ],
    'Business Ethics': [
      'Code of Ethics',
    ],
    'Human Capital': [
      'HR Policy - equal opportunity',
      'Non Discrimination',
      'Intellectual Property',
      'Whistleblower or Issue Escalation',
      'Non Discrimination',
      'Compensation',
      'Harassment',
      'Time',
      'Attendance',
      'Recruitment'
    ],
    'Supply Chain': [
      'Supply Chain Policy (import, export, trade agreements, cross border, etc.)',
    ],
    'Natural Resource Management': [
      'Natural Resouce Policy',
    ],
    'Product Oversight': [
      'Product Design and Governance',
    ],
    'Data Security & Privacy': [
      'Cyber Security',
      'Data Management',
      'Bring Your Own Device',
      'Information Security'
    ],
    'Marketing Integrity': [
      'Ethical Marketing',
    ],
    'Climate Risk': [
      'Climate Change Risk Management Policy',
    ],
    'Systemic Risk': [
      'Workplace health and safety',
      'Business Continuity',
      'Disaster recovery'
    ],
  };

  const options = [
    'ESG Strategy & Execution',
    'Business Ethics',
    'Human Capital',
    'Supply Chain',
    'Natural Resource Management',
    'Product Oversight',
    'Data Security & Privacy',
    'Marketing Integrity',
    'Climate Risk',
    'Systemic Risk',
  ];

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'application/pdf',
    onDrop: (acceptedFiles, rejectedFiles) => {
      const filteredAcceptedFiles = acceptedFiles.filter(file => file.type === 'application/pdf');
      
      if (filteredAcceptedFiles.length !== acceptedFiles.length) {
        const rejectedFileNames = acceptedFiles
          .filter(file => file.type !== 'application/pdf')
          .map(file => file.name);
        
        setRejectedFiles(rejectedFileNames);
        setErrors((prevErrors) => ({
          ...prevErrors,
          files: 'Only PDF files are accepted',
        }));
      } else {
        setRejectedFiles([]);
  
        const duplicateFiles = [];
        filteredAcceptedFiles.forEach((newFile) => {
          const isDuplicate = files.some((file) => file.name === newFile.name && file.size === newFile.size);
          if (isDuplicate) {
            duplicateFiles.push(newFile.name);
          } else {
            setFiles((prevFiles) => [...prevFiles, newFile]);
          }
        });
  
        if (duplicateFiles.length > 0) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            files: `${duplicateFiles.join(', ')} file is already uploaded`,
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            files: '',
          }));
        }
      }
  
      // Handle explicit rejections
      if (rejectedFiles.length > 0) {
        const rejectedFileNames = rejectedFiles.map(rej => rej.file.name);
        setRejectedFiles(rejectedFileNames);
        setErrors((prevErrors) => ({
          ...prevErrors,
          files: 'Only PDF files are accepted',
        }));
      }
    },
  });
  

    const handleNavigation = (path) => {
        navigate(path, { state: { assessmentId:assessmentId } });
    };

    const handleBlur = (field) => (e) => {
        validate(field, e.target.value);
    };

    const removeFile = (fileToRemove) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
    };

    const isFormValid = () => {
        const isValidFiles = files.length > 0 && !errors.files;
        const isValidCompanyName = companyName && !errors.companyName;
        const isValidNumberOfEmployees = numberOfEmployees && !errors.numberOfEmployees;
        const isValidIndustryType = selectedOption && !errors.industryType;
        return isValidFiles && isValidCompanyName && isValidNumberOfEmployees && isValidIndustryType;
    };

  return (
    <>
      <ChakraProvider>
      <Box display='flex' flexDirection='column' height='100%'>
        <Box flex='1' overflowY='auto' display='flex' flexDirection='column' justifyContent='space-between' w="100%" h='fit-content'>
          <Box py={{ base: 7, md: 10 }} px={{ base: 5, md: 10 }}>
          <Breadcrumb separator='>' color="#818589" fontWeight='400' fontSize='sm' mb={2}>
              <BreadcrumbItem>
                <BreadcrumbLink href='/assessment'>Create Assessment</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink as="button" onClick={() => handleNavigation('/upload')}>Upload Files</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          {/* <Heading color='black' fontSize='24px' fontWeight='700' mb={1}>Upload Files</Heading> */}
          <br />
          <Box>
            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={{base:0, md:6}}>
                <GridItem w='100%' h='fit-content'>
                    <Box mb="5">
                        <Text fontWeight="500" fontSize='18px' color='#2E3646' mb="2">
                            Name of company
                        </Text>
                        <Input 
                            variant='outline'
                            border='1px solid #F3F4F6'
                            focusBorderColor={errors.companyName ? '#F04438' : '#2970FF'}
                            bg="white"
                            placeholder='Enter Company name'
                            size='md'
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            onBlur={() => handleBlur('companyName')}
                            borderColor={errors.companyName ? '#F04438' : '#F3F4F6'}
                            sx={{
                                '::placeholder': {
                                    color: '#9DA4AE',
                                },
                                whiteSpace: 'nowrap',
                                overflow: 'auto',
                            }}
                        />
                        {errors.companyName && <Text color="#F04438" fontSize="sm">{errors.companyName}</Text>}
                    </Box>
                </GridItem>
                <GridItem w='100%' h='fit-content'>
                    <Box mb={5}>
                        <Text fontWeight="500" fontSize='18px' color='#2E3646' mb="2">
                            Select the industry type
                        </Text>
                        <Menu >
                        {({ isOpen }) => (<>
                            <MenuButton 
                              ref={menuButtonRef1}
                              w='100%' bg='white'                              
                              border='1px solid'
                              borderColor={ errors.industryType ? '#F04438' : '#F3F4F6' }
                              fontWeight='400'
                              _hover={{ bg: 'white', borderColor: errors.industryType ? '#F04438' : '#2970FF' }}
                              _active={{ bg: 'white', borderColor: errors.industryType ? '#F04438' : '#2970FF' }}
                              _focus={{ bg: 'white', borderColor: errors.industryType ? '#F04438' : '#2970FF' }}
                              as={Button} textAlign='left' 
                              rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                              color={!selectedOption ? "#9DA4AE" : "inherit"}
                            >
                                {selectedOption || 'Select the option'}
                            </MenuButton>
                            <MenuList zIndex={2} width={menuButtonWidth ? `${menuButtonWidth}px` : 'auto'} p={2} borderColor='#EFF4FF' borderRadius='12px' overflowY="auto">
                                {industryOptions?.map((option, index) => (
                                    <React.Fragment key={index}>
                                    <MenuItem
                                        w='100%'
                                        onClick={() => setSelectedOption(option)}
                                        borderRadius="5px"
                                        bg={selectedOption === option ? "#EFF4FF" : "white"}
                                        _hover={{ bg: "#EFF4FF" }}
                                        color="#00359E"
                                        textAlign="left"
                                    >
                                        <Box w="100%" display="flex" justifyContent="space-between" alignItems="center">
                                            {option  || 'Select the option'}
                                            {selectedOption === option && (
                                            <Box as="span" color="#00359E" ml={2}>
                                                <CheckIcon boxSize={3} />
                                            </Box>
                                            )}
                                        </Box>
                                    </MenuItem>
                                    {index < industryOptions.length - 1 && <MenuDivider borderWidth="1px" borderColor="#D1E0FF" />}
                                    </React.Fragment>
                                ))}
                            </MenuList>
                            </>)}
                        </Menu>
                        {errors.industryType && <Text color="#F04438" fontSize="sm">{errors.industryType}</Text>} 
                    </Box>
                    </GridItem>
                </Grid>
                <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
                    <GridItem w='100%' h='fit-content'>
                        <Box mb="5">
                            <Text fontWeight="500" fontSize='18px' color='#2E3646' mb="2">
                                Number of Employees in company
                            </Text>
                            <Input 
                                variant='outline'
                                border='1px solid #F3F4F6'
                                focusBorderColor={errors.numberOfEmployees ? '#F04438' : '#2970FF'}
                                bg="white"
                                type='number'
                                placeholder='Enter size of the company'
                                size='md'
                                value={numberOfEmployees}
                                onChange={(e) => setNumberOfEmployees(e.target.value)}
                                onBlur={() => handleBlur('numberOfEmployees')}
                                borderColor={errors.numberOfEmployees ? '#F04438' : '#F3F4F6'}
                                sx={{
                                    '::placeholder': {
                                        color: '#9DA4AE',
                                    },
                                    whiteSpace: 'nowrap',
                                    overflow: 'auto',
                                }}
                            />
                            {errors.numberOfEmployees && <Text color="#F04438" fontSize="sm">{errors.numberOfEmployees}</Text>}
                        </Box>
                    </GridItem>
                </Grid>
            </Box>
            <br/>
          <Box
            {...getRootProps()}
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            border="2px dashed"
            borderColor={errors.files ? "#F04438":"#2970FF"}
            borderRadius="lg"
            bg={errors.files ? "#FEF3F2":"#EFF4FF"}
            p={4}
            w="100%"
            h='200px'
            zIndex={-1}
            _hover={{ bg: errors.files ? "#FEF3F2" : "#EFF4FF" }}
            cursor="pointer"
          >
            <input type="file" accept="application/pdf" {...getInputProps()}/>
            <ButtonGroup size='sm' isAttached variant='outline' zIndex={1} mb={2}>
              <Button
                bg='white'
                _hover={{ bg: 'white' }}
                borderColor='#D1E0FF'
                color='black'
                leftIcon={<Icon as={fileIcon} w="13px" h="17px" />}
                variant='outline'
                onClick={(e) => { handleFileButtonClick(e); e.stopPropagation(); }}
              >
                Choose Files
              </Button>
              <Menu>
                <MenuButton w='fit-content' bg='white' borderColor='#D1E0FF' _hover={{ bg: 'white' }} as={Button} onClick={(e) => e.stopPropagation()}>
                  <ChevronDownIcon />
                </MenuButton>
                <MenuList width="fit-content" minWidth="0" p={4} borderColor='#EFF4FF' borderRadius='12px'>
                  <MenuItem borderRadius='5px' bg="white" _hover={{ bg: "#EFF4FF" }} fontWeight="400" fontSize="14px" color='#00359E' onClick={(e) => { handleFileButtonClick(e); e.stopPropagation(); }}>From Device</MenuItem>
                  <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
                  <DropboxChooser
                    appKey={APP_KEY}
                    success={(e) => handleDropboxUpload(e)}
                    cancel={() => console.log("closed")}
                    multiselect={true}
                    extensions={['.pdf']}
                  >
                    <MenuItem icon={<Icon as={dropboxIcons} w="24px" h="24px" />} borderRadius='5px' bg="white" _hover={{ bg: "#EFF4FF" }} fontWeight="400" fontSize="14px" color='#00359E'>From Dropbox</MenuItem>
                  </DropboxChooser>
                  <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
                  <MenuItem icon={<Icon as={docusIcons} w="24px" h="24px" />} borderRadius='5px' bg="white" _hover={{ bg: "#EFF4FF" }} fontWeight="400" fontSize="14px" color='#00359E' onClick={(e) => { handleGoogleDriveUpload(); e.stopPropagation(); }}>From Google Drive</MenuItem>
                </MenuList>
              </Menu>
            </ButtonGroup>
            <Text fontSize="xs" fontWeight='normal' color="gray.600">{isDragActive ? 'Drop the files here ...' : 'or drag and drop files here'}</Text>
          </Box>
          {errors.files && <Text color="red.500" fontSize="sm">{errors.files}</Text>}
          {files.length > 0 && <Text color="#2970FF" fontSize="sm">Upload Files Count: {files.length}</Text> }
          <Box w='100%'>
            <Box w='100%'>
                {files.length > 0 ? (
                    <Box p={3} height='fit-content' display='flex' flexWrap='wrap' overflow='hidden' justifyContent='flex-start' alignItems='center' gap={2}>
                    {files.map((file, index) => (
                        <Box key={index} display='flex' justifyContent='center' alignItems='center' py='3px' px='10px' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis' border='1px solid #D1E0FF' borderColor='#D1E0FF' borderRadius='5px' width='fit-content' height='fit-content' backgroundColor='#D1E0FF'>
                        <Text fontSize='12px' style={{ color: '#004EEB', fontWeight: '400' }} pr='2'>{file.name}</Text>
                        <Icon as={circleCloseIcon} w="16px" h="16px" sx={{ cursor: 'pointer' }} onClick={() => removeFile(file)} />
                        </Box>
                    ))}
                    </Box>
                ):(<></>)}
            </Box>
          </Box>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
            accept=".pdf" 
            multiple
          />
          <br />
        <Box w='100%'>
            <Box w={{ base: '100%', md:'60%', lg:'50%', xl:'40%' }}>
              <Text fontWeight="500" fontSize='18px' mb="2" >Add Link</Text>
              <Box display='flex' alignItems='center'>
                <InputGroup size='md'>
                  <Input
                    type='text'
                    placeholder='Type your link to be added here'
                    value={link}
                    variant='outline'
                    borderColor={errors.addlink ? '#F04438' : '#F3F4F6'}
                    focusBorderColor={errors.addlink ? '#F04438' : '#2970FF'}
                    bg="#FFFFFF"
                    onChange={handleLinkChange}
                    sx={{
                      '::placeholder': {
                        color: '#9DA4AE',
                      },
                    }}
                  />
                  <InputRightElement width='2.5rem'>
                    <IconButton  isDisabled={errors.addlink} size='sm' p={0} variant='ghost' _hover={{ bg: 'white' }} onClick={() => AddLink()}>
                      <AddCircleOutlineOutlinedIcon sx={{ fontSize: 25, color: '#9da4ae', '&:hover': { color: errors.addlink ? '#F04438' : '#2970FF', }, }} />
                    </IconButton>
                  </InputRightElement>
                </InputGroup>
                <InfoOutlinedIcon sx={{ ml: 2, fontSize: 20, color: '#D1E0FF' }} />
              </Box>
              {errors.addlink && <Text color="red.500" fontSize="sm">{errors.addlink}</Text>}
            </Box>
            {addlink.length > 0 && (
              <Box p={3} height='fit-content' display='flex' flexWrap='wrap' overflow='hidden' justifyContent='flex-start' alignItems='center' gap={2}>
                {addlink.map((item, index) => (
                  <Box key={index} display='flex' justifyContent='center' alignItems='center' py='3px' px='10px' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis' border='1px solid #D1E0FF' borderColor='#D1E0FF' borderRadius='5px' width='fit-content' height='fit-content' backgroundColor='#D1E0FF'>
                    <Text fontSize='12px' style={{ color: '#004EEB', fontWeight: '400' }} pr='2'>{item}</Text>
                    <Icon as={circleCloseIcon} w="16px" h="16px" sx={{ cursor: 'pointer' }} onClick={() => removelink(item)}/>
                  </Box>
                ))}
              </Box>
            )}
        </Box>
        <br />
        <Box w={{ base: '100%', md:'60%', lg:'50%', xl:'40%' }}>
            <Text fontWeight="500" fontSize='18px' mb="2" >Suggested Documents</Text>
            <Box display='flex' alignItems='center'>
              <Menu >
                {({ isOpen }) => (<>
                  <MenuButton ref={menuButtonRef} w='100%' bg='white' border='2px solid #F3F4F6' fontWeight='400' _hover={{ bg: 'white', borderColor: '#2970FF' }} _active={{ bg: 'white', borderColor: '#D1E0FF' }} _focus={{ bg: 'white' }} as={Button} textAlign='left' rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}>
                    {selectedOptionSuggest}
                  </MenuButton>
                  <MenuList width={menuButtonWidth ? `${menuButtonWidth}px` : 'auto'} p={2} borderColor='#EFF4FF' borderRadius='12px' maxHeight="400px" overflowY="auto"
                    css={{
                      '::-webkit-scrollbar': { display: 'none' },
                      '-ms-overflow-style': 'none',
                      'scrollbar-width': 'none'
                    }}>
                    {options?.map((option, index) => (
                      <React.Fragment key={index}>
                        <MenuItem
                          onClick={() => setSelectedOptionSuggest(option)}
                          borderRadius="5px"
                          bg={selectedOptionSuggest === option ? "#EFF4FF" : "white"}
                          _hover={{ bg: "#EFF4FF" }}
                          color="#00359E"
                          textAlign="left"
                        >
                          <Box w="100%" display="flex" justifyContent="space-between" alignItems="center">
                            {option}
                            {selectedOptionSuggest === option && (
                              <Box as="span" color="#00359E" ml={2}>
                                <CheckIcon boxSize={3} />
                              </Box>
                            )}
                          </Box>
                        </MenuItem>
                        {index < options.length - 1 && <MenuDivider borderWidth="1px" borderColor="#D1E0FF" />}
                      </React.Fragment>
                    ))}
                  </MenuList>
                </>)}
              </Menu>
              <InfoOutlinedIcon sx={{ ml: 2, fontSize: 20, color: '#D1E0FF' }} />
            </Box>
        </Box>
        <br />
        <Box w='100%' bg={'#ECFDF3'} p={6}>
            <Text fontSize='16px' fontWeight="600" color='black' mb={6}>Suggested Documents</Text>
            <Wrap spacingX={20} spacingY={6} align="center" justify="start">
              {sections[selectedOptionSuggest]?.map((feature, index) => (
                <WrapItem key={index}>
                  <VStack >
                    <Box display='flex' justifyContent='center' alignItems='center'>
                      <Icon as={fileIcon} w="13px" h="17px" />
                      <Text px={2} fontSize="14px" fontWeight="500" >{feature}</Text>
                    </Box>
                  </VStack>
                </WrapItem>
              ))}
            </Wrap >
        </Box>
        </Box>
        </Box>
        <Box display='flex' justifyContent='flex-end' py={4} px={5} w='100%' bg='white' borderTop='1px solid #D1E0FF'>
            <Button
              bg={isFormValid() ? "#2970FF" : "#F04438"}
              color='white'
              variant='solid'
              width={{ base: 'fit-content', md: '15%', lg: '25%', xl: '20%' }}
              _hover={{ bg: isFormValid() ? '#7044c4' : '#F04438' }}
              onClick={ generateReport}
              isDisabled={!isFormValid()}
              maxW="100%"
            >
              {isLoading ? <Spinner size="sm" mr="2" /> : null} Generate Report
            </Button>
          </Box>
        </Box>
      </ChakraProvider>
    </>
  );
}

export default Upload;
