import '../../App.css';
import React from "react";
import { ChakraProvider } from '@chakra-ui/react'
import { Input, InputGroup, InputRightElement, Button, Text, Box as ChakraBox, Heading, useToast,Icon } from '@chakra-ui/react'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import axios from 'axios';
import { ReactComponent as shield} from '../../icons/shield.svg'

function Login() {
  const [show, setShow] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errors, setErrors] = React.useState({});
  const toast = useToast()
  const navigate = useNavigate();
  const { login } = useAuth();
  const location = useLocation();

  const handleClick = () => setShow(!show)

  const validate = (field, value) => {
    let error = '';

    switch (field) {
      case 'email':
        const emailRegex = /^(?!.*(\.\.|@\.|\.@|@-))^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!value) {
          error = 'Email is required';
        } else if (!emailRegex.test(value)) {
          error = 'Email is invalid';
        }
        break;
      case 'password':
        if (!value) {
          error = 'Password is required';
        } else if (value.length < 6) {
          error = 'Password must be at least 6 characters';
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));

    return error;
  };

  const handleBlur = (field) => (e) => {
    validate(field, e.target.value);
  };

  const handleSubmit = async () => {
    const fields = [
      'email',
      'password',
    ];

    const validationErrors = {};

    // Validate individual fields
    fields.forEach((field) => {
      const value = eval(field);
      const error = validate(field, value);
      if (error) {
        validationErrors[field] = error;
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const data = {
      emailId: email,
      password: password,
    };
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, data);
      const token = response.data.data.token;
      const refreshToken = response.data.data.refreshToken;
      setEmail('');
      setPassword('');
      login(token, refreshToken)
      navigate('/pastAssessment')
    } catch (error) {
      console.error('Error submitting form:', error);
      if (error.response && error.response.data.message === "No account found for this email address") {
        setErrors({ email: 'Incorrect email address' });
      } else if (error.response && error.response.data.message === "Contact admin for login") {
        setErrors({ email: 'Contact admin for login' });
      } else if (error.response && error.response.data.message === "Invalid password") {
        setErrors({ password: 'Incorrect password' });
      } else {
        console.log(error.response.data)
      }
    }
  }

  return (
    <div className="login">
      {location.state && location.state.success && (
          <ChakraBox display="flex" justifyContent='center' alignItems="center" mb={20}>
            <ChakraBox width='fit-content'  borderRadius="10px" bg="#ECFDF3" p={12} display="flex" justifyContent='center' alignItems="center">
              <ChakraBox width='fit-content'>
                <Icon as={shield} alt="Success Icon" w="24px" h="24px" mr={3} />
              </ChakraBox>
              <ChakraBox>
                <Text color='#027A48' fontWeight='500' fontSize='16px'>Your Password has been changed successfully!</Text>
                <Text color='#6C737F' fontWeight='500' fontSize='12px'>You can login below now with new password</Text>
              </ChakraBox>
            </ChakraBox>
          </ChakraBox>
        )}
      <div className='rounded-box'>
        <ChakraProvider>
          <Heading as="h1" fontWeight='700' fontSize='24px' color={'black'}>Sign in to your account</Heading>
          <br />
          <Text as="h6" fontWeight='500' fontSize='16px' color={'#00359E'} >Please enter your email and password to continue </Text>
          <br />
          <ChakraBox textAlign={'left'} width={'100%'} >
            <ChakraBox mb="5">
              <Text fontSize='14px' fontWeight='500' color='#111927' mb="2">
                Email
              </Text>
              <Input
                variant='outline'
                bg='white'
                focusBorderColor={errors.email ? '#F04438' : '#2970FF'}
                placeholder='Enter your Email address'
                size='md'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={handleBlur('email')}
                borderColor={errors.email ? '#F04438' : '#F3F4F6'}
                sx={{
                  '::placeholder': {
                    color: '#9DA4AE',
                  },
                }}
              />
              {errors.email && <Text color="#F04438" fontSize="16px" fontWeight='400'>{errors.email}</Text>}
            </ChakraBox>
            <ChakraBox mb="5">
              <Text fontSize='14px' fontWeight='500' color='#111927' mb="2">
                Password
              </Text>
              <InputGroup size='md'>
                <Input
                  pr='4.5rem'
                  type={show ? 'text' : 'password'}
                  placeholder='Enter your password'
                  bg='white'
                  focusBorderColor={errors.password ? '#F04438' : '#2970FF'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={handleBlur('password')}
                  borderColor={errors.password ? '#F04438' : '#F3F4F6'}
                  sx={{
                    '::placeholder': {
                      color: '#9DA4AE',
                    },
                  }}
                />
                <InputRightElement width='4.5rem'>
                  <Button h='1.75rem' size='sm' bg={'white'} _hover={{ bg: 'white' }} onClick={handleClick}>
                    {show ? <VisibilityOutlinedIcon sx={{color:'#9DA4AE'}} boxSize={5} /> : <VisibilityOffOutlinedIcon sx={{color:'#9DA4AE'}} boxSize={5} />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {errors.password && <Text color="#F04438" fontSize="16px" fontWeight='400'>{errors.password}</Text>}
            </ChakraBox>
            <Text bg={'#EFF4FF'} fontSize='14px' fontWeight="400" color={'#2970FF'} cursor='pointer' onClick={() => navigate('/forgetPassword')}>
              Forgot password
            </Text>
            <br/>
            <Button bg="#2970FF" color='white' variant='solid' width={'100%'} _hover={{ bg: '#7044c4' }} onClick={handleSubmit}>
              Sign in
            </Button>
          </ChakraBox>
        </ChakraProvider>
      </div>
    </div>
  );
}

export default Login;
