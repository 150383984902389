import React from 'react';
import { Box, Text} from '@chakra-ui/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


const MenuItem = ({ isSelected, isSelectedc, onClick, icon:IconComponent , text, isCollapsed, hasSubMenu, selectedarrowR, isSubMenuOpen, isSubMenuTech, isSubMenuReport, selectedarrow, colorchange }) => {
    return (
        <Box as='button' mb={1} width='100%' borderRadius='10px' bg={isSelected ? '#00359E' : isSelectedc ? '#D1E0FF' : 'initial'} onClick={onClick} _focus={{ boxShadow: 'none' }} display={isCollapsed ? 'flex' : 'block'} justifyContent={isCollapsed ? 'center' : 'initial'}>
            <Box py={isCollapsed ? 3 : 3} px={isCollapsed ? 4 : 6} display='flex' justifyContent='space-between' alignItems='center'>
                <Box display='flex' alignItems='center'>
                    {/* {icon &&
                        <Icon as={icon} size='lg' style={{ color: isSelected ? 'white' : '#111927' }} />
                    } */}
                    {IconComponent && <IconComponent  w="16px" h="20px" style={{ color: isSelected ? 'white' : '#111927', stroke: isSelected ? 'white' : '#111927' }} />}
                    {!isCollapsed && <Text pl={2} fontSize='14px' color={isSelected ? 'white' : '#111927'} fontWeight="400">{text}</Text>}
                </Box>
                {isSubMenuReport && (isSubMenuOpen ? <ExpandLessIcon style={{ color: selectedarrowR ? 'white' : 'initial' }} /> : <ExpandMoreIcon style={{ color: selectedarrowR ? 'white' : 'initial' }} />)}
                {isSubMenuTech && (isSubMenuOpen ? <ExpandLessIcon style={{ color: selectedarrow ? 'white' : 'initial' }} /> : <ExpandMoreIcon style={{ color: selectedarrow ? 'white' : 'initial' }} />)}
            </Box>
        </Box>
    );
};

export default MenuItem;