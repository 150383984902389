import '../../App.css';
import React from "react";
import {ChakraProvider, Text, Box, Heading, Icon, Grid, GridItem, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Button, Input, Textarea, Modal,
  useDisclosure,
  Flex,
  Spacer, useBreakpointValue, useToast} from '@chakra-ui/react';
import {ChevronDownIcon, ChevronUpIcon, EditIcon, SmallCloseIcon, CheckIcon} from '@chakra-ui/icons'
import { get, post, remove } from '../../api'
import ModalAddIndustry from './modeladdindustry';
import ModalAddSection from './modeladdsection';
import ModalAddAssetion from './modeladdassertion';
import ModalEditAssetion from './modeleditassertion';
import ModalEditSection from './modaleditsection'
import { ReactComponent as DeleteIcon } from '../../icons/deleteIcon.svg';
import { useSpring, animated } from '@react-spring/web';


const Section = ({ name, isExpanded, onExpand, assertions, sectionId, industryTypeId, refreshSections }) => {
  const toast = useToast();
  const { isOpen: isAsserionModalOpen, onOpen: onAsserionModalOpen, onClose: onAsserionModalClose } = useDisclosure();
  const { isOpen: isEditAssertionModalOpen, onOpen: onEditAssertionModalOpen, onClose: onEditAssertionModalClose } = useDisclosure();
  const { isOpen: isEditSectionModalOpen, onOpen: onEditSectionModalOpen, onClose: onEditSectionModalClose } = useDisclosure();
  const [selectedAssertionId, setSelectedAssertionId] = React.useState(null);
  const contentRef = React.useRef(null);
  const [height, setHeight] = React.useState(0);

  const handleEditIconClick = (assertionId) => {
    setSelectedAssertionId(assertionId);
    onEditAssertionModalOpen();
  };

  const buttonWidth = useBreakpointValue({ sm: "fit-content", md: "fit-content", xl: "fit-content" });

  const handleDeleteSection = async (industryTypeId, sectionId) => {
    try {
      const response = await remove(`/reportmanagement/industry/${industryTypeId}/section/${sectionId}`);

      toast({
          title: response.data.message,
          description: 'Section has been deleted successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
      });
      refreshSections()  
    } catch (error) {
      console.error('Error submitting form:', error);
      if(error.response.status === 400)
      {
        toast({
          title: 'Error',
          description: error.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
      else if(error.response.status === 404){
        toast({
          title: 'Error',
          description: error.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        toast({
          title: 'Error',
          description: error.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  };

  const handleDeleteAssertion = async (industryTypeId, sectionId, assertionId) => {
    try {
      const response = await remove(`/reportmanagement/industry/${industryTypeId}/section/${sectionId}/assertion/${assertionId}`);

      toast({
          title: response.data.message,
          description: 'Asserion has been deleted successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
      });
      refreshSections()
    } catch (error) {
      console.error('Error submitting form:', error);
      if(error.response.status === 400)
      {
        toast({
          title: 'Error',
          description: error.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
      else if(error.response.status === 404){
        toast({
          title: 'Error',
          description: error.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        toast({
          title: 'Error',
          description: error.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  };

  React.useEffect(() => {
    if (isExpanded) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isExpanded]);

  const animationProps = useSpring({
    height: height,
    opacity: isExpanded ? 1 : 0,
    overflow: 'hidden',
  });

  return (
    <Box bg='white' p='15px' h='fit-content' border={isExpanded ? '1px solid #2970FF' : '1px solid #D1E0FF'} borderRadius='12px'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Text fontSize='18px' fontWeight="500" color='black' mr={3}>{name}</Text>
          {isExpanded && <Icon as={EditIcon} boxSize={5} color='#2970FF' cursor='pointer' onClick={onEditSectionModalOpen}/>}
            <ModalEditSection
              isOpen={isEditSectionModalOpen}
              onClose={onEditSectionModalClose}
              industryTypeId={industryTypeId}
              sectionId={sectionId}
              refreshSections={refreshSections}
            />
        </Box>
        <Icon as={isExpanded ? ChevronUpIcon : ChevronDownIcon} boxSize={5} cursor='pointer' onClick={onExpand} />
      </Box>
      {/* {isExpanded && ( */}
        <animated.div style={animationProps}>
          <Box ref={contentRef}>
          <br />
          {assertions.map((assertion, index) => (
            <React.Fragment key={index}>
              <Box bg='white' p='15px' h='fit-content' border='1px solid #D1E0FF' borderRadius='12px'>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Text fontSize='16px' fontWeight="500" color='#6C737F' mr={3}>{assertion.assertionName}</Text>
                  <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Icon as={EditIcon} boxSize={5} color='#2970FF' cursor='pointer' mr={3} onClick={() => handleEditIconClick(assertion.assertionId)} />
                    {selectedAssertionId === assertion.assertionId && (
                      <ModalEditAssetion
                        isOpen={isEditAssertionModalOpen}
                        onClose={onEditAssertionModalClose}
                        industryTypeId={industryTypeId}
                        sectionId={sectionId}
                        assertionId={selectedAssertionId}
                      />
                    )}
                    <Icon as={DeleteIcon} boxSize={5} color='#F04438' cursor='pointer' onClick={() => handleDeleteAssertion(industryTypeId, sectionId, assertion.assertionId)} />
                  </Box>
                </Box>
              </Box>
              <br />
            </React.Fragment>
          ))}
          <Box width={{ sm: "100%", md: "100%", xl: '27%' }}>
            <Flex>
              <Button variant="ghost" bg="#FEF3F2" _hover={{ bg: "#FEF3F2" }} color="#F04438" width={buttonWidth} onClick={() => handleDeleteSection(industryTypeId, sectionId)}>
                Delete section
              </Button>
              <Spacer />
              <Button bg="#2970FF" color="white" variant="solid" width={buttonWidth} _hover={{ bg: "#7044c4" }} onClick={onAsserionModalOpen}>
                Add Assertion
              </Button>
              <ModalAddAssetion isOpen={isAsserionModalOpen} onClose={onAsserionModalClose} sectionId={sectionId} industryTypeId={industryTypeId} refreshSections={refreshSections} />
            </Flex>
          </Box>
          </Box>
        </animated.div>
      {/* )} */}
    </Box>
  );
};

function Reportassertion() {
  const [expandedSection, setExpandedSection] = React.useState(null);
  const [selectedOptionIndustry, setSelectedOptionIndustry] = React.useState(null);
  // const [numberOfEmployees, setNumberOfEmployees] = React.useState('');
  const [industryOptions, setIndustryOptions] = React.useState([]);
  const [sections, setSections] = React.useState([]);
  const [menuButtonWidth, setMenuButtonWidth] = React.useState(null);
  const menuButtonRef = React.useRef();
  const toast = useToast()

  React.useEffect(() => {
    if (menuButtonRef.current) {
      setMenuButtonWidth(menuButtonRef.current.clientWidth);
    }
  }, [menuButtonRef.current]);  

    
  const {
    isOpen: isIndustryModalOpen,
    onOpen: onIndustryModalOpen,
    onClose: onIndustryModalClose,
  } = useDisclosure();
  
  const {
    isOpen: isSectionModalOpen,
    onOpen: onSectionModalOpen,
    onClose: onSectionModalClose,
  } = useDisclosure();
  
  const fetchSections = React.useCallback(async () => {
    try {
      if (selectedOptionIndustry && selectedOptionIndustry.id) {
        const response = await get(`/reportmanagement/industry/${selectedOptionIndustry.id}/section`);
        const fetchedSections = response.data.data.section.map(section => ({
          sectionId: section.sectionId,
          name: section.name,
          assertions: section.assertions.map(assertion => ({
            assertionId: assertion.assertionId,
            assertionName: assertion.assertionQuestion
          }))
        }));
        setSections(fetchedSections);
      }
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  }, [selectedOptionIndustry]);

  const deleteIndustry = async (e) => {
    e.preventDefault();
    try {
      const response = await remove(`/reportmanagement/industry/${selectedOptionIndustry.id}`);
      toast({
        title: response.data.message,
        description: 'Industry has been Deleted successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      fetchSections()
      fetchIndustryTypes()
    } catch (error) {
      console.error('Error delete industry:', error);
    }
  }

  const fetchIndustryTypes = async () => {
    try {
      const response = await get('/reportmanagement/industry');
        
      const industryTypes = response.data.data.map(item => ({
        id: item.industryTypeId,
        type: item.industryType,
      }));
      setIndustryOptions(industryTypes);
      if (industryTypes.length > 0) {
        setSelectedOptionIndustry(industryTypes[0]);
      }
    } catch (error) {
      console.error('Error fetching industry types:', error);
    }
  };
  
  React.useEffect(() => {    
    fetchIndustryTypes();
  }, []);
  
  React.useEffect(() => {
    fetchSections();
    setExpandedSection(null); 
  }, [selectedOptionIndustry, fetchSections]);
  
  return (
    <>
      <ChakraProvider>
        <Box px={[4, 6, 10]} py={10} w="100%" h="fit-content" overflowY="scroll">
          <Box>
            <Heading size="md" fontSize={{ base: '20px', md: '24px' }} fontWeight="700" mb={2}>
              Admin Tools
            </Heading>
          </Box>
          <br />
          <Box
            w="100%"
            bg="#F5F8FF"
            p={{ base: '8px', sm: '10px' }}
            h="fit-content"
            border="1px solid #D1E0FF"
            borderRadius="12px"
          >
            <Box>
              <Grid templateColumns={{ base: '1fr', sm: 'repeat(7, 1fr)' }} gap={{ base:2, sm: 6 }}>
                <GridItem w='100%' h="fit-content" colSpan={5}>
                  <Menu>
                    {({ isOpen }) => (
                      <>
                        <MenuButton
                          ref={menuButtonRef}
                          w='97%'
                          bg='white'
                          border='1px solid #F3F4F6'
                          fontWeight='400'
                          _hover={{ bg: 'white', borderColor: '#D1E0FF' }}
                          _active={{ bg: 'white', borderColor: '#D1E0FF' }}
                          _focus={{ bg: 'white' }}
                          as={Button}
                          textAlign='left'
                          rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        >
                          {selectedOptionIndustry ? selectedOptionIndustry.type : 'Select an industry'}
                        </MenuButton>
                          <MenuList p={2} width={menuButtonWidth ? `${menuButtonWidth}px` : 'auto'} borderColor='#EFF4FF' borderRadius='12px' maxHeight="400px" overflowY="auto"> 
                          {industryOptions.map((option, index) => (
                            <React.Fragment key={option.id}>
                              <MenuItem
                                onClick={() => setSelectedOptionIndustry(option)}
                                borderRadius="5px"
                                bg={selectedOptionIndustry.type === option.type ? "#EFF4FF" :"white"}            
                                _hover={{ bg: "#EFF4FF" }}
                                color="#00395E"
                                textAlign="left"
                              >
                                <Flex justify="space-between" align="center" w="100%">
                                  <Box>{option.type}</Box>
                                    {selectedOptionIndustry.type === option.type && (
                                      <Box as="span" color="#00359E" ml={2}>
                                        <CheckIcon boxSize={3} />
                                      </Box>
                                    )}
                                </Flex>
                              </MenuItem>
                              {index < industryOptions.length - 1 && <MenuDivider borderWidth="1px" borderColor="#D1E0FF" />}
                            </React.Fragment>
                          ))}
                        </MenuList>
                      </>
                    )}
                </Menu>
                </GridItem>
                <GridItem  w="100%" h="fit-content">
                  <Button
                    variant="outline"
                    borderColor="#F04438"
                    bg="white"
                    _hover={{ bg: '#F04438', color: 'white' }}
                    color="#F04438"
                    onClick={deleteIndustry}
                  >
                    Delete Industry
                  </Button>
                </GridItem>
                <GridItem w="100%" h="fit-content">
                  <Button
                    bg="#2970FF"
                    color="white"
                    variant="solid"
                    _hover={{ bg: '#7044c4' }}
                    onClick={onIndustryModalOpen}
                  >
                    Add New Industry
                  </Button>
                  <ModalAddIndustry isOpen={isIndustryModalOpen} onClose={onIndustryModalClose} refreshIndustry={fetchIndustryTypes} />
                </GridItem>
              </Grid>
            </Box>
          </Box>
          <br />
          <Box
            w="100%"
            bg="#F5F8FF"
            px={{ base: '15px', sm: '30px' }}
            py={{ base: '10px', sm: '20px' }}
            h="fit-content"
            border="1px solid #D1E0FF"
            borderRadius="12px"
          >
            <Box display="flex" justifyContent="space-between" alignItems='center' flexDirection='row'>
              <Text fontSize={{ base: '18px', sm: '20px' }} fontWeight="600" color="black">
                Sections
              </Text>
              <Button
                mt={{ base: 1, sm: 0 }}
                variant="outline"
                borderColor="#2970FF"
                bg="white"
                _hover={{ bg: '#2970FF', color: 'white' }}
                color="#2970FF"
                onClick={onSectionModalOpen}
              >
                Add New Section
              </Button>
              <ModalAddSection
                isOpen={isSectionModalOpen}
                onClose={onSectionModalClose}
                refreshSections={fetchSections}
                industryId={selectedOptionIndustry?.id}
              />
            </Box>
            {sections.map((section, index) => (
              <React.Fragment key={section.sectionId}>
                <br />
                <Section
                  industryTypeId={selectedOptionIndustry.id}
                  sectionId={section.sectionId}
                  name={section.name}
                  isExpanded={expandedSection === index}
                  onExpand={() => setExpandedSection(expandedSection === index ? null : index)}
                  assertions={section.assertions}
                  refreshSections={fetchSections}
                />
              </React.Fragment>
            ))}
          </Box>
        </Box>
      </ChakraProvider>
    </>
  );
}
  
export default Reportassertion;
  
