import '../../App.css';
import React from "react";
import {
  ChakraProvider, InputGroup, InputRightElement, Text, Box, Heading, Input, Grid, GridItem, Flex, Menu, MenuButton,
   MenuDivider, MenuItem, MenuList, Button, useToast, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom'
import { ChevronDownIcon, ChevronUpIcon, CheckIcon } from '@chakra-ui/icons';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { get, post } from '../../api';

function Createuser() {
  const toast = useToast();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = React.useState('');
  const [selectedOptionIndustry, setSelectedOptionIndustry] = React.useState('');
  const [industryOptions, setIndustryOptions] = React.useState([]);

  const [name, setName] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [numberOfEmployees, setNumberOfEmployees] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const [errors, setErrors] = React.useState({});
  const [show, setShow] = React.useState(false);
  const [showC, setShowC] = React.useState(false);
  const [menuButtonWidth, setMenuButtonWidth] = React.useState(null);
  const menuButtonRef = React.useRef();

  React.useEffect(() => {
    if (menuButtonRef.current) {
      setMenuButtonWidth(menuButtonRef.current.clientWidth);
    }
  }, [menuButtonRef.current]);

  const handleClick = () => setShow(!show);
  const handleClickC = () => setShowC(!showC);

  const roleOptions = ['Admin', 'User'];

  const validate = (field, value) => {
    let error = '';

    switch (field) {
      case 'name':
        const nameRegex = /^[a-zA-Z]+( [a-zA-Z]+){0,29}$/;
        if (!value) {
          error = 'Name is required';
        } else if (!nameRegex.test(value)) {
          error = 'Name is invalid';
        }
        break;
      case 'phoneNumber':
        const phoneRegex = /^(\+?\d{1,4}[-.\s]?)?(\(?\d{1,4}\)?[-.\s]?)?(\d{1,4}[-.\s]?){1,3}\d{1,4}$/;
        if (!value) {
          error = 'Phone number is required';
        } else if (!phoneRegex.test(value)) {
          error = 'Phone number is invalid';
        }
        break;
      case 'email':
        const emailRegex = /^(?!.*(\.\.|@\.|\.@|@-))^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!value) {
          error = 'Email is required';
        } else if (!emailRegex.test(value)) {
          error = 'Email is invalid';
        }
        break;
      case 'companyName':
        const companyNameRegex = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+){0,49}$/;
        if (!value) {
          error = 'Company name is required';
        } else if (!companyNameRegex.test(value)) {
          error = 'Company name is invalid';
        }
        break;
      case 'numberOfEmployees':
        if (!value) {
          error = 'Company Size is required';
        } else if (isNaN(value)) {
          error = 'Company Size must be a number';
        } else if (value <= 0) {
          error = 'Company Size must be a greater than 0';
        }
        break;
      case 'password':
        if (!value) {
          error = 'Password is required';
        } else if (value.length < 6) {
          error = 'Password must be at least 6 characters';
        }
        break;
      case 'confirmPassword':
        if (!value) {
          error = 'Confirm password is required';
        } else if (value !== password) {
          error = 'Passwords do not match';
        }
        break;
      case 'industryType':
        if (value === '') {
          error = 'Industry type is required';
        }
        break;
      case 'role':
        if (value === '') {
          error = 'Role is required';
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));

    return error;
  };

  const handleBlur = (field) => (e) => {
    validate(field, e.target.value);
  };

  const handleChange = (field) => (e) => {
    const value = e.target.value;
    switch (field) {
      case 'name':
        setName(value);
        break;
      case 'phoneNumber':
        setPhoneNumber(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'companyName':
        setCompanyName(value);
        break;
      case 'numberOfEmployees':
        setNumberOfEmployees(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        break;
      default:
        break;
    }
    validate(field, value);
  };

  const handleSubmit = async () => {
    const fields = [
      'name',
      'phoneNumber',
      'email',
      'companyName',
      'numberOfEmployees',
      'password',
      'confirmPassword',
      'industryType',
      'role',
    ];

    const validationErrors = {};

    fields.forEach((field) => {
      const value = eval(field === 'industryType' ? 'selectedOptionIndustry' : field === 'role' ? 'selectedOption' : field);
      const error = validate(field, value);
      if (error) {
        validationErrors[field] = error;
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const data = {
      userName: name,
      phoneNumber: phoneNumber,
      emailId: email,
      companyName: companyName,
      companySize: numberOfEmployees,
      industryType: selectedOptionIndustry,
      role: selectedOption,
      password: password,
      confirmPassword: confirmPassword,
    };

    try {
      const response = await post(`users/user`, data);

      toast({
        title: response.data.message,
        description: 'User has been created successfully',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
        onCloseComplete: () => {
          navigate('/user');
        },
      });
      setName('');
      setEmail('');
      setPhoneNumber('');
      setNumberOfEmployees('');
      setPassword('');
      setSelectedOption('');
      setSelectedOptionIndustry('');
      setConfirmPassword('');
      setCompanyName('');
      setErrors({});
    } catch (error) {
      console.error('Error submitting form:', error);
      if (error.response && error.response.data.message === "Email is already registered") {
        setErrors({ email: 'Email is already registered' });
      }
      if (error.response && error.response.data.message === "Phone number is already registered") {
        setErrors({ phoneNumber: 'Phone number is already registered' });
      }
      if (error.response && error.response.data.message === "Passwords do not match") {
        setErrors({ confirmPassword: 'Passwords do not match' });
      }
    }
  };

  React.useEffect(() => {
    const fetchIndustryTypes = async () => {
      try {
        const response = await get('/reportmanagement/industry');
        const industryTypes = response.data.data.map(item => item.industryType);
        setIndustryOptions(industryTypes);
      } catch (error) {
        console.error('Error fetching industry types:', error);
      }
    };
    fetchIndustryTypes();
  }, []);

  const isFormValid = () => {
    return !Object.values(errors).some(error => error) &&
      name && phoneNumber && email && companyName && numberOfEmployees && password && confirmPassword && selectedOptionIndustry && selectedOption;
  };

  return (
    <ChakraProvider>
      <Box display='flex' flexDirection='column' height='100%'>
        <Box flex='1' overflowY='auto' display='flex' flexDirection='column' justifyContent='space-between' w="100%" h='fit-content'>
          <Box py={{ base: 7, md: 10 }} px={{ base: 5, md: 10 }}>
            <Breadcrumb separator='>' color="#818589" fontWeight='400' fontSize='sm' mb={2}>
              <BreadcrumbItem>
                <BreadcrumbLink href='/user'>User</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink href='/createUser'>Add User</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <Box>
              <Heading size='md' fontSize={{ base: '20px', md: '24px' }} fontWeight='700' mb={2}>
                Create User
              </Heading>
            </Box>
            <Box mb={5} w='100%' bg='#F5F8FF' p={{ base: '20px', md: '30px' }} h='fit-content' border='1px solid #D1E0FF' borderRadius='12px'>
              <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={{ base: 1, md: 2, lg: 5 }}>
                <GridItem w='100%' h='fit-content'>
                  <Box mb="5">
                    <Text fontWeight="medium" fontSize='14px' mb="2">
                      Name of user
                    </Text>
                    <Input
                      variant='outline'
                      border='1px solid #F3F4F6'
                      focusBorderColor={errors.name ? 'red.500' : '#D1E0FF'}
                      bg="white"
                      placeholder='Name of a user'
                      size='md'
                      value={name}
                      onChange={handleChange('name')}
                      onBlur={handleBlur('name')}
                      borderColor={errors.name ? 'red.500' : '#F3F4F6'}
                      sx={{
                        '::placeholder': {
                          color: '#9DA4AE',
                        },
                        whiteSpace: 'nowrap',
                        overflow: 'auto',
                      }}
                    />
                    {errors.name && <Text color="red.500" fontSize="sm">{errors.name}</Text>}
                  </Box>
                </GridItem>
                <GridItem w='100%' h='fit-content'>
                  <Box mb="5">
                    <Text fontWeight="medium" fontSize='14px' mb="2">
                      Phone Number
                    </Text>
                    <Input
                      type='number'
                      variant='outline'
                      border='1px solid #F3F4F6'
                      focusBorderColor={errors.phoneNumber ? 'red.500' : '#D1E0FF'}
                      bg="white"
                      placeholder='Enter Phone number'
                      size='md'
                      value={phoneNumber}
                      onChange={handleChange('phoneNumber')}
                      onBlur={handleBlur('phoneNumber')}
                      borderColor={errors.phoneNumber ? 'red.500' : '#F3F4F6'}
                      sx={{
                        '::placeholder': {
                          color: '#9DA4AE',
                        },
                        whiteSpace: 'nowrap',
                        overflow: 'auto',
                      }}
                    />
                    {errors.phoneNumber && <Text color="red.500" fontSize="sm">{errors.phoneNumber}</Text>}
                  </Box>
                </GridItem>
                <GridItem w='100%' h='fit-content'>
                  <Box mb="5">
                    <Text fontWeight="medium" fontSize='14px' mb="2">
                      Email Address
                    </Text>
                    <Input
                      variant='outline'
                      border='1px solid #F3F4F6'
                      focusBorderColor={errors.email ? 'red.500' : '#D1E0FF'}
                      bg="white"
                      placeholder='Enter Email address'
                      size='md'
                      value={email}
                      onChange={handleChange('email')}
                      onBlur={handleBlur('email')}
                      borderColor={errors.email ? 'red.500' : '#F3F4F6'}
                      sx={{
                        '::placeholder': {
                          color: '#9DA4AE',
                        },
                        whiteSpace: 'nowrap',
                        overflow: 'auto',
                      }}
                    />
                    {errors.email && <Text color="red.500" fontSize="sm">{errors.email}</Text>}
                  </Box>
                </GridItem>
                <GridItem w='100%' h='fit-content'>
                  <Box mb="5">
                    <Text fontWeight="medium" fontSize='14px' mb="2">
                      Name of company
                    </Text>
                    <Input
                      variant='outline'
                      border='1px solid #F3F4F6'
                      focusBorderColor={errors.companyName ? 'red.500' : '#D1E0FF'}
                      bg="white"
                      placeholder='Enter Company name'
                      size='md'
                      value={companyName}
                      onChange={handleChange('companyName')}
                      onBlur={handleBlur('companyName')}
                      borderColor={errors.companyName ? 'red.500' : '#F3F4F6'}
                      sx={{
                        '::placeholder': {
                          color: '#9DA4AE',
                        },
                        whiteSpace: 'nowrap',
                        overflow: 'auto',
                      }}
                    />
                    {errors.companyName && <Text color="red.500" fontSize="sm">{errors.companyName}</Text>}
                  </Box>
                </GridItem>
                <GridItem w='100%' h='fit-content'>
                  <Box mb="5">
                    <Text fontWeight="medium" fontSize='14px' mb="2">
                      Company size
                    </Text>
                    <Input
                      type='number'
                      variant='outline'
                      border='1px solid #F3F4F6'
                      focusBorderColor={errors.numberOfEmployees ? 'red.500' : '#D1E0FF'}
                      bg="white"
                      placeholder='Enter size of the company'
                      size='md'
                      value={numberOfEmployees}
                      onChange={handleChange('numberOfEmployees')}
                      onBlur={handleBlur('numberOfEmployees')}
                      borderColor={errors.numberOfEmployees ? 'red.500' : '#F3F4F6'}
                      sx={{
                        '::placeholder': {
                          color: '#9DA4AE',
                        },
                        whiteSpace: 'nowrap',
                        overflow: 'auto',
                      }}
                    />
                    {errors.numberOfEmployees && <Text color="red.500" fontSize="sm">{errors.numberOfEmployees}</Text>}
                  </Box>
                </GridItem>
                <GridItem w='100%' h='fit-content'>
                  <Box mb={5}>
                    <Text fontWeight="medium" fontSize='14px' mb="2">
                      Select the industry type
                    </Text>
                    <Menu>
                      {({ isOpen }) => (
                        <>
                          <MenuButton
                            ref={menuButtonRef}
                            w='100%'
                            value={selectedOptionIndustry}
                            bg='white'
                            border='1px solid #F3F4F6'
                            borderColor={errors.industryType ? 'red.500' : '#F3F4F6'}
                            fontWeight='400'
                            _hover={{ bg: 'white', borderColor: errors.industryType ? 'red.500' : '#D1E0FF' }}
                            _active={{ bg: 'white', borderColor: errors.industryType ? 'red.500' : '#D1E0FF' }}
                            _focus={{ bg: 'white', borderColor: errors.industryType ? 'red.500' : '#D1E0FF' }}
                            as={Button}
                            textAlign='left'
                            rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                            color={!selectedOptionIndustry ? "#9DA4AE" : "inherit"}
                          >
                            {selectedOptionIndustry || 'Select the option'}
                          </MenuButton>
                          <MenuList width={menuButtonWidth ? `${menuButtonWidth}px` : 'auto'} p={2} borderColor='#EFF4FF' borderRadius='12px' maxHeight="400px" overflowY="auto">
                            {industryOptions.map((option, index) => (
                              <React.Fragment key={index}>
                                <MenuItem
                                  onClick={() => { setSelectedOptionIndustry(option); validate('industryType', option); }}
                                  borderRadius="5px"
                                  bg={selectedOptionIndustry === option ? "#EFF4FF" : "white"}
                                  _hover={{ bg: "#EFF4FF" }}
                                  color="#00395E"
                                  textAlign="left"
                                >
                                  <Flex justify="space-between" align="center" w="100%">
                                    <Box>{option}</Box>
                                    {selectedOptionIndustry === option && (
                                      <Box as="span" color="#00359E" ml={2}>
                                        <CheckIcon boxSize={3} />
                                      </Box>
                                    )}
                                  </Flex>
                                </MenuItem>
                                {index < industryOptions.length - 1 && <MenuDivider borderWidth="1px" borderColor="#D1E0FF" />}
                              </React.Fragment>
                            ))}
                          </MenuList>
                        </>
                      )}
                    </Menu>
                    {errors.industryType && <Text color="red.500" fontSize="sm">{errors.industryType}</Text>}
                  </Box>
                </GridItem>
                <GridItem w='100%' h='fit-content'>
                  <Box mb="5">
                    <Text fontWeight="medium" fontSize='14px' mb="2">
                      Password
                    </Text>
                    <InputGroup size='md'>
                      <Input
                        variant='outline'
                        type={show ? 'text' : 'password'}
                        border='1px solid #F3F4F6'
                        focusBorderColor={errors.password ? 'red.500' : '#D1E0FF'}
                        bg="white"
                        placeholder='Enter your password'
                        size='md'
                        value={password}
                        onChange={handleChange('password')}
                        onBlur={handleBlur('password')}
                        borderColor={errors.password ? 'red.500' : '#F3F4F6'}
                        sx={{
                          '::placeholder': {
                            color: '#9DA4AE',
                          },
                          whiteSpace: 'nowrap',
                          overflow: 'auto',
                        }}
                      />
                      <InputRightElement width='4.5rem'>
                        <Button h='1.75rem' size='sm' bg={'white'} _hover={{ bg: 'white' }} onClick={handleClick}>
                          {show ? <VisibilityOutlinedIcon sx={{color:'#9DA4AE'}} boxSize={5} /> : <VisibilityOffOutlinedIcon sx={{color:'#9DA4AE'}} boxSize={5} />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    {errors.password && <Text color="red.500" fontSize="sm">{errors.password}</Text>}
                  </Box>
                </GridItem>
                <GridItem w='100%' h='fit-content'>
                  <Box mb="5">
                    <Text fontWeight="medium" fontSize='14px' mb="2">
                      Confirm Password
                    </Text>
                    <InputGroup size='md'>
                      <Input
                        variant='outline'
                        type={showC ? 'text' : 'password'}
                        border='1px solid #F3F4F6'
                        focusBorderColor={errors.confirmPassword ? 'red.500' : '#D1E0FF'}
                        bg="white"
                        placeholder='Enter confirm password'
                        size='md'
                        value={confirmPassword}
                        onChange={handleChange('confirmPassword')}
                        onBlur={handleBlur('confirmPassword')}
                        borderColor={errors.confirmPassword ? 'red.500' : '#F3F4F6'}
                        sx={{
                          '::placeholder': {
                            color: '#9DA4AE',
                          },
                          whiteSpace: 'nowrap',
                          overflow: 'auto',
                        }}
                      />
                      <InputRightElement width='4.5rem'>
                        <Button h='1.75rem' size='sm' bg={'white'} _hover={{ bg: 'white' }} onClick={handleClickC}>
                          {showC ? <VisibilityOutlinedIcon sx={{color:'#9DA4AE'}} boxSize={5} /> : <VisibilityOffOutlinedIcon sx={{color:'#9DA4AE'}} boxSize={5} />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    {errors.confirmPassword && <Text color="red.500" fontSize="sm">{errors.confirmPassword}</Text>}
                  </Box>
                </GridItem>
                <GridItem w='100%' h='fit-content'>
                  <Box mb={5}>
                    <Text fontWeight="medium" fontSize='14px' mb="2">
                      Select the role
                    </Text>
                    <Menu>
                      {({ isOpen }) => (
                        <>
                          <MenuButton
                            ref={menuButtonRef}
                            w='100%'
                            value={selectedOption}
                            bg='white'
                            border='1px solid #F3F4F6'
                            borderColor={errors.role ? 'red.500' : '#F3F4F6'}
                            fontWeight='400'
                            _hover={{ bg: 'white', borderColor: errors.role ? 'red.500' : '#D1E0FF' }}
                            _active={{ bg: 'white', borderColor: errors.role ? 'red.500' : '#D1E0FF' }}
                            _focus={{ bg: 'white', borderColor: errors.role ? 'red.500' : '#D1E0FF' }}
                            as={Button}
                            textAlign='left'
                            rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                            color={!selectedOption ? "#9DA4AE" : "inherit"}
                          >
                            {selectedOption || 'Select the option'}
                          </MenuButton>
                          <MenuList width={menuButtonWidth ? `${menuButtonWidth}px` : 'auto'} p={2} borderColor='#EFF4FF' borderRadius='12px' maxHeight="400px" overflowY="auto">
                            {roleOptions.map((option, index) => (
                              <React.Fragment key={index}>
                                <MenuItem
                                  onClick={() => { setSelectedOption(option); validate('role', option); }}
                                  borderRadius="5px"
                                  bg={selectedOption === option ? "#EFF4FF" : "white"}
                                  _hover={{ bg: "#EFF4FF" }}
                                  color="#00395E"
                                  textAlign="left"
                                >
                                  <Flex justify="space-between" align="center" w="100%">
                                    <Box>{option}</Box>
                                    {selectedOption === option && (
                                      <Box as="span" color="#00359E" ml={2}>
                                        <CheckIcon boxSize={3} />
                                      </Box>
                                    )}
                                  </Flex>
                                </MenuItem>
                                {index < roleOptions.length - 1 && <MenuDivider borderWidth="1px" borderColor="#D1E0FF" />}
                              </React.Fragment>
                            ))}
                          </MenuList>
                        </>
                      )}
                    </Menu>
                    {errors.role && <Text color="red.500" fontSize="sm">{errors.role}</Text>}
                  </Box>
                </GridItem>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box display='flex' justifyContent='flex-end' py={4} px={5} w='100%' bg='white' borderTop='1px solid #D1E0FF'>
          <Button
            bg={isFormValid() ? "#2970FF" : "#F04438"}
            color='white'
            variant='solid'
            width={{ base: 'fit-content', md: '15%', lg: '18%', xl: '15%' }}
            _hover={{ bg: isFormValid() ? '#7044c4' : '#F04438' }}
            onClick={handleSubmit}
            isDisabled={!isFormValid()}
            maxW="100%"
          >
            Create user
          </Button>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default Createuser;
