import '../../App.css';
import React from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import {ChakraProvider, Text, Box, Heading, Input, Button, Icon, InputGroup, InputRightAddon,Menu, MenuButton, MenuList, MenuItem, MenuDivider, useDisclosure, Badge} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { SearchIcon } from '@chakra-ui/icons'
import AdminAssessmentTableComponent from '../../components/table/adminassesmenttable';
import { ReactComponent as filterIcon } from '../../icons/filterIcon.svg';
import useAdminCheck from '../../useAdminCheck';

function Pastassessment() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin, userId } = useAdminCheck();
  const [searchTerm, setSearchTerm] = React.useState(location.state?.searchTerm || "");
  const [submittedSearchTerm, setSubmittedSearchTerm] = React.useState(location.state?.searchTerm || "");
  const [sortOption, setSortOption] = React.useState(location.state?.sortOption || { sortBy: 'assessmentName', sortOrder: 'asc' });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItems, setSelectedItems] = React.useState(location.state?.selectedItems || []);
  const [currentPage, setCurrentPage] = React.useState(location.state?.currentPage || 1);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = () => {
    setSubmittedSearchTerm(searchTerm);
    setCurrentPage(1)
    updateNavigateState(currentPage, searchTerm, sortOption, selectedItems);
  };

  const handleSort = (sortBy, statuses) => {
    setSortOption((prev) => ({
      ...prev,
      [sortBy]: statuses
    }));
    setCurrentPage(1)
    updateNavigateState(1, searchTerm, { ...sortOption, [sortBy]: statuses }, statuses);
  };

  const handleSelect = (item) => {
    let newSelectedItems = [];

    if (item === 'Select Both') {
      if (selectedItems.includes('Completed') && selectedItems.includes('In Process')) {
        newSelectedItems = selectedItems.filter(i => i !== 'Completed' && i !== 'In Process');
      } else {
        newSelectedItems = ['Completed', 'In Process'];
      }
    } else {
      if (selectedItems.includes(item)) {
        newSelectedItems = selectedItems.filter((i) => i !== item);
      } else {
        newSelectedItems = [...selectedItems, item];
      }
    }

    setSelectedItems(newSelectedItems);
    handleSort('status', newSelectedItems);
  };

  const isSelected = (item) => selectedItems.includes(item) || (item === 'Select Both' && selectedItems.length === 2 && selectedItems.includes('Completed') && selectedItems.includes('In Process'));  

  const updateNavigateState = (page, search, sort, selected) => {
    navigate(location.pathname, { state: { currentPage: page, searchTerm: search, sortOption: sort, selectedItems: selected } });
  };



  React.useEffect(() => {
    if (searchTerm === "") {
      handleSearchSubmit();
    }
  }, [searchTerm]);

  return (
    <>
      <ChakraProvider>
        <Box px={{ base: 4, md: 10 }} py={10} w='100%' h='fit-content' overflow-y='scroll'>
            <Box>
                <Heading size='md' fontSize='25px' mb={1} >Past Assessment</Heading>
            </Box>
            <br/>
            <Box w='100%' display='flex' justifyContent='space-between' flexWrap="wrap" pt={2}>
            <InputGroup w={{ base: '100%', md: '80%', lg:'80%', xl:'87%', '2xl':'88%' }}
            sx={{
              '@media screen and (min-width: 1800px)': {
                width: '90%'
              },
              '@media screen and (min-width: 2300px)': {
                width: '92%'
              }
            }}>
              <Input 
                type='text' 
                border={`1px solid ${'#D1E0FF'}`} 
                focusBorderColor='#c5d7fc' 
                size='md' 
                placeholder='Find by Assessment Name, User Name, Company Name' 
                value={searchTerm} 
                onChange={handleSearchChange} 
              />
              <InputRightAddon 
                as={Button} 
                backgroundColor={'#00359E'} 
                leftIcon={<SearchIcon />}
                border={`1px solid ${'#D1E0FF'}`} 
                color={'white'}  
                _hover={{ bg: "#00359E" }} 
                onClick={() => handleSearchSubmit()}
              >
                Search
              </InputRightAddon>
            </InputGroup>
            <Menu isOpen={isOpen} onClose={onClose} closeOnSelect={false}>
              <MenuButton 
                as={Button} 
                rightIcon={ <Icon as={filterIcon}  w="16px" h="20px" style={{ color: selectedItems.length > 0 ? 'white' : '#111927', stroke: selectedItems.length > 0 ? 'white' : '#111927' }} />}
                py={'8px'}
                px={'15px'}
                bg={selectedItems.length > 0 ? "#2970FF" : "#F5F8FF"}
                border="1px solid #D1E0FF"
                color={selectedItems.length > 0 ? "white" : "black"}
                _hover={{ bg: selectedItems.length > 0 ? "#2970FF" : "#F5F8FF" }}
                _active={{ bg: selectedItems.length > 0 ? "#2970FF" : "#F5F8FF" }}
                _focus={{ bg: selectedItems.length > 0 ? "#2970FF" : "#F5F8FF" }}
                textAlign="right"
                h="fit-content"
                mt={{ base: 2, md: 0 }}
                onClick={isOpen ? onClose : onOpen}
              >
                {selectedItems.length > 0 && (
                  <Badge colorScheme="white" bg="white" color="black" borderRadius="full" px='6px' py='2px' mr={2}>
                    {selectedItems.length}
                  </Badge>
                )}
                Status
              </MenuButton>
              <MenuList width="fit-content" minWidth="0" p={4} borderColor='#EFF4FF' borderRadius='12px'>
                {['Completed', 'In Process','Select Both'].map((item, index, array) => (
                  <React.Fragment key={item}>
                    <MenuItem
                      borderRadius='5px'
                      bg={isSelected(item) ? "#EFF4FF" : "white"}
                      _hover={{ bg: "#EFF4FF" }}
                      color='#00359E'
                      onClick={() => handleSelect(item)}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      {item}
                      {isSelected(item) && (
                        <Box as="span" color="#00359E" ml={2}>
                          <CheckIcon boxSize={3} />
                        </Box>
                      )}
                    </MenuItem>
                    {index < array.length - 1 && (
                      <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
                    )}
                  </React.Fragment>
                ))}
              </MenuList>
            </Menu>
            </Box>
            <br/>
            <AdminAssessmentTableComponent 
              searchTerm={submittedSearchTerm} 
              sortOption={sortOption} 
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              updateNavigateState={updateNavigateState}
              isAdmin={isAdmin}
              userId={userId}
            />
        </Box>
      </ChakraProvider>
    </>
  );
}

export default Pastassessment;
