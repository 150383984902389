import React, { useEffect, useState, useRef } from 'react';
import { Text, Textarea, Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, Button, Box, useToast } from '@chakra-ui/react';
import { get, patch } from '../../api';

const EditDashboardModal = ({ isOpen, onClose, reportId, refreshData }) => {
    const toast = useToast();
    const [overallScore, setOverallScore] = useState(0);
    const [overallDescription, setOverallDescription] = useState("");
    const [taskSummary, setTaskSummary] = useState("");
    const [errors, setErrors] = useState({});
    const textAreaRef = useRef(null);
    const textAreaTaskRef = useRef(null);

    const resizeTextAreas = () => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto';
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
        if (textAreaTaskRef.current) {
            textAreaTaskRef.current.style.height = 'auto';
            textAreaTaskRef.current.style.height = `${textAreaTaskRef.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        if (isOpen) {
            const fetchData = async () => {
                try {
                    const response = await get(`report/overall/${reportId}`);
                    setOverallScore(response.data.data.overall.score);
                    setOverallDescription(response.data.data.overall.description);
                    setTaskSummary(response.data.data.taskSummary);
                    resizeTextAreas();
                } catch (error) {
                    console.error('Error fetching data', error);
                }
            };
            fetchData();
        }
    }, [isOpen, reportId]);

    useEffect(() => {
        if (isOpen) {
            resizeTextAreas();
        }
    }, [isOpen, taskSummary, overallDescription]);

    const handleSubmit = async () => {
        const data = {
            reportId,
            overall: { score: overallScore, description: overallDescription },
            taskSummary: taskSummary
        };

        try {
            const response = await patch(`report/overall/${reportId}`, data);

            toast({
                title: response.data.message,
                description: 'Dashboard is updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });
            refreshData();
            onClose();
        } catch (error) {
            console.error('Error submitting form:', error);
            const errorMessage = error.response?.data?.message || 'An error occurred';
            toast({
                title: 'Error',
                description: errorMessage,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent my={4} p={4}>
                <ModalBody>
                    <Box mb="5">
                        <Text fontWeight="medium" fontSize='14px' mb="2">
                            Overall Score
                        </Text>
                        <Textarea
                            px={3}
                            border='1px solid #F3F4F6'
                            focusBorderColor={errors.overallScore ? 'red.500' : '#2970FF'}
                            bg="#F5F8FF"
                            fontSize="14px"
                            borderRadius='10px'
                            placeholder='Overall score'
                            size='md' resize={'none'}
                            value={overallScore}
                            lineHeight='21px'
                            minHeight='fit-content'
                            onChange={(e) => setOverallScore(e.target.value)}
                            borderColor={errors.overallScore ? 'red.500' : '#D1E0FF'}
                            onInput={(e) => {
                                e.target.style.height = 'auto';
                                e.target.style.height = `${e.target.scrollHeight}px`;
                            }}
                            sx={{
                                '::placeholder': {
                                  color: '#9DA4AE',
                                },
                              }}
                            css={{
                                '::-webkit-scrollbar': { display: 'none' },
                                '-ms-overflow-style': 'none',
                                'scrollbar-width': 'none'
                            }}
                        />
                        {errors.overallScore && <Text color="red.500" fontSize="sm">{errors.overallScore}</Text>}
                    </Box>
                    <Box mb="5">
                        <Text fontWeight="medium" fontSize='14px' mb="2">
                            Overall Description
                        </Text>
                        <Textarea
                            ref={textAreaTaskRef}
                            px={3}
                            border='1px solid #F3F4F6'
                            focusBorderColor={errors.overallDescription ? 'red.500' : '#2970FF'}
                            bg="#F5F8FF"
                            fontSize="14px"
                            borderRadius='10px'
                            placeholder='Overall description'
                            size='md' resize={'none'}
                            value={overallDescription}
                            lineHeight='21px'
                            minHeight='fit-content'
                            onChange={(e) => setOverallDescription(e.target.value)}
                            borderColor={errors.overallDescription ? 'red.500' : '#D1E0FF'}
                            onInput={(e) => {
                                e.target.style.height = 'auto';
                                e.target.style.height = `${e.target.scrollHeight}px`;
                            }}
                            sx={{
                                '::placeholder': {
                                  color: '#9DA4AE',
                                },
                              }}
                            css={{
                                '::-webkit-scrollbar': { display: 'none' },
                                '-ms-overflow-style': 'none',
                                'scrollbar-width': 'none'
                            }}
                        />
                        {errors.overallDescription && <Text color="red.500" fontSize="sm">{errors.overallDescription}</Text>}
                    </Box>
                    <Box mb="5">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Text fontWeight="medium" fontSize='14px' mb="2">
                                Task Summary
                            </Text>
                        </Box>
                        <Textarea
                            ref={textAreaRef}
                            px={3}
                            border='1px solid #F3F4F6'
                            bg='#F5F8FF'
                            borderRadius='10px'
                            fontSize="14px"
                            focusBorderColor={errors.taskSummary ? 'red.500' : '#2970FF'}
                            placeholder='Task summary'
                            size='md' resize={'none'}
                            value={taskSummary}
                            lineHeight='21px'
                            minHeight='fit-content'
                            textAlign="justify"
                            onChange={(e) => setTaskSummary(e.target.value)}
                            borderColor={errors.taskSummary ? 'red.500' : '#D1E0FF'}
                            onInput={(e) => {
                                e.target.style.height = 'auto';
                                e.target.style.height = `${e.target.scrollHeight}px`;
                            }}
                            sx={{
                                '::placeholder': {
                                    color: '#9DA4AE',
                                },
                            }}
                            css={{
                                '::-webkit-scrollbar': { display: 'none' },
                                '-ms-overflow-style': 'none',
                                'scrollbar-width': 'none'
                            }}
                        />
                        {errors.taskSummary && <Text color="red.500" fontSize="sm">{errors.taskSummary}</Text>}
                    </Box>
                </ModalBody>
                <ModalFooter width='100%' display='flex' justifyContent='space-between' alignItems='center'>
                    <Button variant='ghost' bg='#EFF4FF' _hover={{ bg: '#EFF4FF' }} color='#2970FF' width='47.5%' onClick={onClose}>Cancel</Button>
                    <Button
                        bg="#2970FF"
                        color='white'
                        variant='solid'
                        width='47.5%'
                        _hover={{ bg: '#7044c4' }}
                        onClick={handleSubmit}
                    >
                        Save and Submit
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default EditDashboardModal;
